import React from "react";
import {
    IRegisterPayload,
    registerRequest,
} from "../../shared/services/auth/auth.service";
import { Form, Input, Button } from "antd";
import { Link } from "react-router-dom";

const Register = () => {
    const onFinish = (values: IRegisterPayload) => {
        registerRequest(values)
            .then((resp) => {})
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Form
            name="Login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout={"vertical"}
        >
            <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                    {
                        required: true,
                        message: "Please input your First Name!",
                    },
                ]}
            >
                <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                    { required: true, message: "Please input your Last Name!" },
                ]}
            >
                <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                rules={[
                    { required: true, message: "Please input your Email!" },
                ]}
            >
                <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[
                    { required: true, message: "Please input your Password!" },
                ]}
            >
                <Input type="password" placeholder="Password" />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" block>
                    Register
                </Button>
                <div className={"text-center mt-2"}>
                    Already have an account.{" "}
                    <Link to={"/auth/"}>Login Now.</Link>
                </div>
            </Form.Item>
        </Form>
    );
};

export default Register;
