import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import axiosClient from "../../config/axiosClient";
import Dragger from "antd/lib/upload/Dragger";

const FileUpload = ({ triggerUpload, onUploadComplete, existingFile }: any) => {
    const [fileList, setFileList] = useState<any>([]);

    useEffect(() => {
        if (triggerUpload) {
            if (fileList.length) {
                const formData = new FormData();

                fileList.forEach((file: any) => {
                    formData.append("file", file);
                    formData.append("type", file.type.split("/")[0]);
                });

                axiosClient.post("/file/upload", formData).then((resp) => {
                    onUploadComplete(resp);
                    setFileList([]);
                });
            } else {
                onUploadComplete(existingFile);
            }
        }
    }, [triggerUpload]);

    return (
        <Dragger
            name="file"
            showUploadList={true}
            className={
                "p-6 text-center border rounded flex justify-center cursor-pointer hover:bg-primary-white transition items-center border-dashed w-full"
            }
            beforeUpload={(file) => {
                setFileList((currentState: any) => {
                    return [...currentState, file];
                });
                return false;
            }}
            onRemove={(file) => {
                setFileList((currentState: any) => {
                    return [
                        ...currentState.filter((x: any) => x.uid !== file.uid),
                    ];
                });
            }}
        >
            {!fileList.length && !existingFile && (
                <div>Click or Drag here to upload</div>
            )}
            {!fileList.length && existingFile && (
                <>
                    <div
                        className=" bg-center  bg-contain mx-auto bg-no-repeat"
                        style={{
                            backgroundImage: `url(${existingFile.url})`,
                            width: 200,
                            height: 200,
                        }}
                    ></div>
                    <div>Click or Drag here to change</div>
                </>
            )}
        </Dragger>
    );
};

export default FileUpload;
