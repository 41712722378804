import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Input, Popconfirm } from "antd";
import FileUpload from "../../shared/components/FileUpload";
import { AuthContext } from "../../shared/context/auth";
import {
    addChannel,
    ChannelListContext,
    deleteChannel,
    getChannelById,
    updateChannel,
} from "../../shared/services/channel/channel.service";
import { toast } from "react-toastify";
import { useHistory, useRouteMatch } from "react-router";
import { isEmpty } from "lodash";
import { QuestionCircleOutlined } from "@ant-design/icons";

const ChannelForm = () => {
    const { authState } = useContext(AuthContext);

    const [formData, setFormData] = useState({});

    const [triggerUpload, setTriggerUpload] = useState(false);

    const [triggerSubmit, setTriggerSubmit] = useState(false);

    const [channelForm] = Form.useForm();

    const { dispatch } = useContext(ChannelListContext);

    const { params } = useRouteMatch<{ id: string }>();

    const [channel, setChannel] = useState<any>();

    const history = useHistory();

    useEffect(() => {
        if (params.id) {
            getChannelById(params.id)
                .then((data) => {
                    channelForm.setFieldsValue({
                        name: data.response.name,
                        description: data.response.description,
                    });
                    setChannel(data.response);
                    setFormData((currentState) => {
                        return {
                            ...currentState,
                            id: data.response.id,
                            admins: data.response.admins.map((x: any) => x.id),
                        };
                    });
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
        } else {
            channelForm.resetFields();
            setTriggerUpload(false);
            setTriggerSubmit(false);
            setChannel(null);
        }
    }, [params]);

    useEffect(() => {
        if (triggerSubmit && !isEmpty(formData)) {
            if (channel) {
                updateChannel(formData)
                    .then((resp) => {
                        toast.success("Channel saved successfully.");
                        dispatch({ action: "REFRESH_LIST" });
                        setTriggerUpload(false);
                        setTriggerSubmit(false);
                    })
                    .catch((err) => {
                        console.log("🚀 ~ err", err);
                    });
            } else {
                addChannel({ ...formData, admins: [authState.user.id] })
                    .then((resp) => {
                        toast.success("Channel saved successfully.");
                        channelForm.resetFields();
                        dispatch({ action: "REFRESH_LIST" });
                        setTriggerUpload(false);
                        setTriggerSubmit(false);
                    })
                    .catch((err) => {
                        console.log("🚀 ~ err", err);
                    });
            }
        }
    }, [triggerSubmit]);

    return (
        <>
            <div className="text-2xl mb-4">
                {channel ? "Edit" : "Add"} Channel
            </div>
            <div className="w-2/4">
                <Form
                    form={channelForm}
                    name="UserForm"
                    layout="vertical"
                    initialValues={{
                        name: "",
                        description: "",
                    }}
                    onFinish={(values) => {
                        setFormData((currentState) => {
                            return {
                                ...currentState,
                                ...values,
                            };
                        });

                        setTriggerUpload(true);
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input channel name!",
                            },
                        ]}
                    >
                        <Input placeholder="Channel name" />
                    </Form.Item>

                    <Form.Item label="Cover Image">
                        <FileUpload
                            triggerUpload={triggerUpload}
                            onUploadComplete={(media: any) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        cover: media?._id || media?.id,
                                    };
                                });

                                setTriggerSubmit(true);
                            }}
                            existingFile={channel?.cover}
                        />
                    </Form.Item>

                    <Form.Item name="description" label="Description">
                        <Input.TextArea rows={4} />
                    </Form.Item>

                    <Form.Item>
                        <div className="flex gap-4">
                            {channel ? (
                                <Popconfirm
                                    title="Are you sure? This action is not reversible."
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{ color: "red" }}
                                        />
                                    }
                                    onConfirm={() => {
                                        deleteChannel(channel.id)
                                            .then((resp) => {
                                                dispatch({
                                                    action: "REFRESH_LIST",
                                                });
                                                toast.success(
                                                    "Channel deleted successfully."
                                                );
                                                history.push("./");
                                            })
                                            .catch((err) => {
                                                console.log("🚀 ~ err", err);
                                            });
                                    }}
                                >
                                    <Button
                                        className={"flex-1"}
                                        htmlType="button"
                                    >
                                        Delete
                                    </Button>
                                </Popconfirm>
                            ) : (
                                <div className={"flex-1"}>&nbsp;</div>
                            )}
                            <Button className={"flex-1"} htmlType="reset">
                                Cancel
                            </Button>
                            <Button
                                className={"flex-1"}
                                type="primary"
                                htmlType="submit"
                            >
                                Save
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default ChannelForm;
