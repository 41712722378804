import apolloClient from "../../../config/apolloClient";
import {
    exchangeRefreshTokenMutation,
    loginMutation,
    registerMutation,
} from "./auth.gql";

export interface ILoginPayload {
    email: string;
    password: string;
}

export interface IRegisterPayload extends ILoginPayload {
    firstname: string;
    lastname: string;
}

export const clearLocalStorage = () => {
    const instructionStateBakeACake = localStorage.getItem(
        "instructionStateBakeACake"
    );
    const instructionStateBuildACar = localStorage.getItem(
        "instructionStateBuildACar"
    );
    localStorage.clear();
    localStorage.setItem(
        "instructionStateBakeACake",
        instructionStateBakeACake || ""
    );
    localStorage.setItem(
        "instructionStateBuildACar",
        instructionStateBuildACar || ""
    );
};

export const loginRequest = async (payload: ILoginPayload) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: loginMutation,
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.Login };
};

export const exchangeRefreshTokenRequest = async (refreshToken: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: exchangeRefreshTokenMutation,
        variables: {
            input: {
                refreshToken,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    if (!data.ExchangeRefreshToken) {
        return {
            errors: "Failed to exchange token.",
        };
    }

    return { response: data.ExchangeRefreshToken };
};

export const registerRequest = async (payload: IRegisterPayload) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: registerMutation,
        variables: {
            input: {
                ...payload,
            },
        },
    });

    if (errors) {
        return { errors };
    }

    return data.Register;
};

export const getValidToken = async (operationName = "") => {
    let token = JSON.parse(localStorage.getItem("token") || "{}");

    if (token) {
        if (token.access_token && operationName !== "ExchangeRefreshToken") {
            const access_token = token.access_token.split(".");

            const access_token_exp = JSON.parse(
                atob(access_token[1]) as any
            ).exp;

            if (Date.now() >= access_token_exp * 1000) {
                console.log("Token expired !!!");
                const { response, errors } = await exchangeRefreshTokenRequest(
                    token.refresh_token
                );

                if (errors) {
                    localStorage.clear();
                    window.location.reload();
                }

                token = {
                    access_token: response.access_token,
                    refresh_token: response.refresh_token,
                };

                localStorage.setItem("token", JSON.stringify(token));
            }
        }
    }

    return token;
};
