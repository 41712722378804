import gql from "graphql-tag";

export const userFieldsFragment = gql`
    fragment userFields on User {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
        role {
            id
            name
        }
    }
`;

export const usersQuery = gql`
    query Users($pagingInput: PagingInput) {
        users(pagination: $pagingInput) {
            data {
                id
                firstName
                lastName
                role {
                    name
                }
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }
`;

export const userQuery = gql`
    query User($id: String!) {
        user(id: $id) {
            ...userFields
            channels {
                id
                name
            }
        }
    }

    ${userFieldsFragment}
`;

export const createUserMutation = gql`
    mutation CreateUser($createUserInput: CreateUserInput!) {
        createUser(createUserInput: $createUserInput) {
            ...userFields
        }
    }

    ${userFieldsFragment}
`;

export const updateUserMutation = gql`
    mutation UpdateUser($updateUserInput: UpdateUserInput!) {
        updateUser(updateUserInput: $updateUserInput) {
            ...userFields
        }
    }

    ${userFieldsFragment}
`;

export const removeUserMutation = gql`
    mutation RemoveUser($id: String!) {
        removeUser(id: $id) {
            ...userFields
        }
    }

    ${userFieldsFragment}
`;
