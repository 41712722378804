import React, { useContext, useEffect, useState } from "react";
import { Button, Input } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import { getContentsByChannelId as getGroupedContentsByChannelId } from "../../shared/services/content/content.service";
import ContentIcon from "../../shared/components/ContentIcon";
import { ChannelListContext } from "../../shared/services/channel/channel.service";

const { Search } = Input;

const Explorer = () => {
    const { channelState } = useContext(ChannelListContext);

    const { params } = useRouteMatch<{ channelId: string }>();

    const [groupedContents, setGroupedContents] = useState<any>({
        video: [],
        audio: [],
        image: [],
    });

    const [channel, setChannel] = useState<any>();

    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        if (params.channelId) {
            getGroupedContents();
        }
    }, [params]);

    useEffect(() => {
        getGroupedContents();
    }, [searchQuery]);

    useEffect(() => {
        if (channelState.channels) {
            setChannel((currentState: any) => {
                const found = channelState.channels.find(
                    (x: any) => x.id === params.channelId
                );
                return { ...found };
            });
        }
    }, [channelState, params]);

    const getGroupedContents = () => {
        getGroupedContentsByChannelId(params?.channelId, searchQuery)
            .then((resp) => {               
                setGroupedContents(() => {
                    return { ...resp.response };
                });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    };

    return (
        <div className={"space-y-4"}>
            <div className="flex justify-between">
                <div className={"text-xl"}>{channel?.name}</div>
                <div>
                    <Button type="primary" ghost>
                        <Link to={`/content/channel-form/${params.channelId}`}>
                            Edit Channel
                        </Link>
                    </Button>
                </div>
            </div>

            <div className="flex gap-6">
                <Search
                    placeholder="Search"
                    style={{ width: 300 }}
                    onSearch={(keyWord) => {
                        setSearchQuery(keyWord);
                    }}
                />
            </div>

            {/* <div className={"flex justify-between"}>
                <div className="text-lg">Videos</div>
                {groupedContents.video.length ? (
                    <Link
                        to={`/content/${params.channelId}/all?type=Video&search=${searchQuery}`}
                    >
                        See all
                    </Link>
                ) : (
                    ""
                )}
            </div>
            <div className={"grid grid-cols-5 gap-4"}>
                {groupedContents.video.map((content: any) => {
                    return (
                        <Link
                            key={content.id}
                            className={"border  border-secondary-gray rounded"}
                            to={`./${content.id}/edit`}
                        >
                            <div
                                className={
                                    "aspect-w-1 aspect-h-1 bg-secondary-gray w-full bg-center bg-no-repeat "
                                }
                                style={{
                                    backgroundImage: `url(${content.cover.url})`,
                                }}
                            >
                                <ContentIcon type={content.media.type} />
                            </div>
                            <div className={"p-2 bg-primary-white"}>
                                {content.title}
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div>
                {!groupedContents.video.length && (
                    <div>
                        No video contents available, click{" "}
                        <Link to={"/content/form"}>here</Link> to add a new
                        content.
                    </div>
                )}
                &nbsp;
            </div> */}

            <div className={"flex justify-between"}>
                <div className="text-lg">Stiry+ Original Story</div>
                {groupedContents?.stiry_story?.length ? (
                    <Link
                        to={`/content/${params.channelId}/all?type=Video&search=${searchQuery}`}
                    >
                        See all
                    </Link>
                ) : (
                    ""
                )}
            </div>
            <div className={"grid grid-cols-5 gap-4"}>
                {groupedContents?.stiry_story?.map((content: any) => {
                    return (
                        <Link
                            key={content.id}
                            className={"border  border-secondary-gray rounded"}
                            to={`./${content.id}/edit`}
                        >
                            <div
                                className={
                                    "aspect-w-1 aspect-h-1 bg-secondary-gray w-full bg-center bg-no-repeat "
                                }
                                style={{
                                    backgroundImage: `url(${content.cover.url})`,
                                }}
                            >
                                <ContentIcon type={content.media.type} />
                            </div>
                            <div className={"p-2 bg-primary-white"}>
                                {content.title}
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div>
                {!groupedContents?.stiry_story?.length && (
                    <div>
                        No Stiry+ Original Story available, click{" "}
                        <Link to={"/content/form"}>here</Link> to add a new
                        content.
                    </div>
                )}
                &nbsp;
            </div>

            <div className={"flex justify-between"}>
                <div className="text-lg">Short</div>
                {groupedContents?.short?.length ? (
                    <Link
                        to={`/content/${params.channelId}/all?type=Video&search=${searchQuery}`}
                    >
                        See all
                    </Link>
                ) : (
                    ""
                )}
            </div>
            <div className={"grid grid-cols-5 gap-4"}>
                {groupedContents?.short?.map((content: any) => {
                    return (
                        <Link
                            key={content.id}
                            className={"border  border-secondary-gray rounded"}
                            to={`./${content.id}/edit`}
                        >
                            <div
                                className={
                                    "aspect-w-1 aspect-h-1 bg-secondary-gray w-full bg-center bg-no-repeat "
                                }
                                style={{
                                    backgroundImage: `url(${content.cover.url})`,
                                }}
                            >
                                <ContentIcon type={content.media.type} />
                            </div>
                            <div className={"p-2 bg-primary-white"}>
                                {content.title}
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div>
                {!groupedContents?.short?.length && (
                    <div>
                        No short available, click{" "}
                        <Link to={"/content/form"}>here</Link> to add a new
                        content.
                    </div>
                )}
                &nbsp;
            </div>

            <div className={"flex justify-between"}>
                <div className="text-lg">Video Podcast</div>
                {groupedContents?.video_podcast?.length ? (
                    <Link
                        to={`/content/${params.channelId}/all?type=Video&search=${searchQuery}`}
                    >
                        See all
                    </Link>
                ) : (
                    ""
                )}
            </div>
            <div className={"grid grid-cols-5 gap-4"}>
                {groupedContents?.video_podcast?.map((content: any) => {
                    return (
                        <Link
                            key={content.id}
                            className={"border  border-secondary-gray rounded"}
                            to={`./${content.id}/edit`}
                        >
                            <div
                                className={
                                    "aspect-w-1 aspect-h-1 bg-secondary-gray w-full bg-center bg-no-repeat "
                                }
                                style={{
                                    backgroundImage: `url(${content.cover.url})`,
                                }}
                            >
                                <ContentIcon type={content.media.type} />
                            </div>
                            <div className={"p-2 bg-primary-white"}>
                                {content.title}
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div>
                {!groupedContents?.video_podcast?.length && (
                    <div>
                        No video podcast available, click{" "}
                        <Link to={"/content/form"}>here</Link> to add a new
                        content.
                    </div>
                )}
                &nbsp;
            </div>

            <div className={"flex justify-between"}>
                <div className="text-lg">Reels</div>
                {groupedContents?.reels?.length ? (
                    <Link
                        to={`/content/${params.channelId}/all?type=Video&search=${searchQuery}`}
                    >
                        See all
                    </Link>
                ) : (
                    ""
                )}
            </div>
            <div className={"grid grid-cols-5 gap-4"}>
                {groupedContents?.reels?.map((content: any) => {
                    return (
                        <Link
                            key={content.id}
                            className={"border  border-secondary-gray rounded"}
                            to={`./${content.id}/edit`}
                        >
                            <div
                                className={
                                    "aspect-w-1 aspect-h-1 bg-secondary-gray w-full bg-center bg-no-repeat "
                                }
                                style={{
                                    backgroundImage: `url(${content.cover.url})`,
                                }}
                            >
                                <ContentIcon type={content.media.type} />
                            </div>
                            <div className={"p-2 bg-primary-white"}>
                                {content.title}
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div>
                {!groupedContents?.reels?.length && (
                    <div>
                        No reels available, click{" "}
                        <Link to={"/content/form"}>here</Link> to add a new
                        content.
                    </div>
                )}
                &nbsp;
            </div>

            <div className={"flex justify-between"}>
                <div className="text-lg">Audios</div>
                {groupedContents.audio.length ? (
                    <Link
                        to={`/content/${params.channelId}/all?type=Audio&search=${searchQuery}`}
                    >
                        See all
                    </Link>
                ) : (
                    ""
                )}
            </div>
            <div className={"grid grid-cols-5 gap-4"}>
                {groupedContents.audio.map((content: any) => {
                    return (
                        <Link
                            key={content.id}
                            className={"border  border-secondary-gray rounded"}
                            to={`./${content.id}/edit`}
                        >
                            <div
                                className={
                                    "aspect-w-1 aspect-h-1 bg-secondary-gray w-full bg-center bg-no-repeat "
                                }
                                style={{
                                    backgroundImage: `url(${content.cover.url})`,
                                }}
                            >
                                <ContentIcon type={content.media.type} />
                            </div>
                            <div className={"p-2 bg-primary-white"}>
                                {content.title}
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div>
                {!groupedContents.audio.length && (
                    <div>
                        No audio contents available, click{" "}
                        <Link to={"/content/form"}>here</Link> to add a new
                        content.
                    </div>
                )}
                &nbsp;
            </div>

            <div className={"flex justify-between"}>
                <div className="text-lg">Images</div>
                {groupedContents.image.length ? (
                    <Link
                        to={`/content/${params.channelId}/all?type=Image&search=${searchQuery}`}
                    >
                        See all
                    </Link>
                ) : (
                    ""
                )}
            </div>
            <div className={"grid grid-cols-5 gap-4"}>
                {groupedContents.image.map((content: any) => {
                    return (
                        <Link
                            key={content.id}
                            className={"border  border-secondary-gray rounded"}
                            to={`./${content.id}/edit`}
                        >
                            <div
                                className={
                                    "aspect-w-1 aspect-h-1 bg-secondary-gray w-full bg-center bg-no-repeat "
                                }
                                style={{
                                    backgroundImage: `url(${content.cover.url})`,
                                }}
                            >
                                <ContentIcon type={content.media.type} />
                            </div>
                            <div className={"p-2 bg-primary-white"}>
                                {content.title}
                            </div>
                        </Link>
                    );
                })}
            </div>
            <div>
                {!groupedContents.image.length && (
                    <div>
                        No image contents available, click{" "}
                        <Link to={"/content/form"}>here</Link> to add a new
                        content.
                    </div>
                )}
                &nbsp;
            </div>
        </div>
    );
};

export default Explorer;
