import { createContext } from "react";
import apolloClient from "../../../config/apolloClient";
import {
    channelQuery,
    channelsQuery,
    createChannelMutation,
    removeChannelMutation,
    updateChannelMutation,
} from "./channel.gql";

export const ChannelListContext = createContext<any>({});

export const getChannels = async (filter = {}) => {
    const { data, errors } = await apolloClient.query({
        query: channelsQuery,
        variables: {
            pagingInput: {
                limit: 0,
                orderBy: "createdAt",
                orderByDir: "DESC",
            },
            filter,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.channels };
};

export const getChannelById = async (id: string) => {
    const { data, errors } = await apolloClient.query({
        query: channelQuery,
        variables: {
            id,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.channel };
};

export const addChannel = async (formData: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createChannelMutation,
        variables: {
            createChannelInput: {
                ...formData,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createChannel };
};

export const updateChannel = async (formData: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: updateChannelMutation,
        variables: {
            updateChannelInput: {
                ...formData,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.updateChannel };
};

export const deleteChannel = async (id: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: removeChannelMutation,
        variables: {
            id,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.channel };
};
