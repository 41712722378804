import {
    FileImageOutlined,
    SoundOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import React from "react";

const ContentIcon = ({ type }: { type: string }) => {
    return (
        <div className={"py-1 px-2"}>
            {type === "Video" && <VideoCameraOutlined />}
            {type === "Audio" && <SoundOutlined />}
            {type === "Image" && <FileImageOutlined />}
        </div>
    );
};

export default ContentIcon;
