import React, { useContext, useEffect, useReducer, useState } from "react";
import {
    Link,
    Route,
    Switch,
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import { AuthContext } from "../../shared/context/auth";
import {
    getUsers,
    UserListContext,
} from "../../shared/services/user/user.service";
import UserForm from "./Form";
import styles from "./index.module.less";

const User = () => {
    const history = useHistory();

    const { path } = useRouteMatch();

    const { authState } = useContext(AuthContext);

    const [users, setUsers] = useState<any>([]);

    const [refreshChannelList, setRefreshUserList] = useState(0);

    const [state, dispatch] = useReducer((currentState: any, payload: any) => {
        if (payload.action === "REFRESH_LIST") {
            setRefreshUserList((currentState: number) => {
                return currentState + 1;
            });
        }
        return {};
    }, {});

    useEffect(() => {
        getUsers()
            .then((resp) => {
                setUsers((currentState: any) => {
                    return resp.response.data;
                });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [refreshChannelList]);

    useEffect(() => {
        if (authState.user.role.name === "Content Manager") {
            history.push("/");
        }
    }, []);

    return (
        <UserListContext.Provider value={{ dispatch }}>
            <div className={"space-y-6"}>
                <div className={"text-2xl"}>Users</div>

                <div className={"flex gap-6"}>
                    <div className={"w-1/4 space-y-4"}>
                        <div className={styles.listItem + " border-dashed"}>
                            <Link to={`${path}/form`}>Add new User</Link>
                        </div>
                        {users.map((user: any) => {
                            return (
                                <Link
                                    to={`${path}/form/${user.id}`}
                                    className={styles.listItem}
                                    key={user.id}
                                >
                                    <div className="text-base">
                                        {user.firstName} {user.lastName}
                                    </div>

                                    <div>
                                        {user.role.name === "Content Manager"
                                            ? "Business User"
                                            : user.role.name}
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                    <div className={"bg-primary-gray rounded p-6 w-3/4"}>
                        <Switch>
                            <Route
                                exact
                                path={`${path}/form`}
                                component={UserForm}
                            />
                            <Route
                                exact
                                path={`${path}/form/:id?`}
                                component={UserForm}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </UserListContext.Provider>
    );
};

export default User;
