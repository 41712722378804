import React, { useState } from "react";
import { Form, Input, Button, DatePicker, Modal } from "antd";
import { addCode } from "../../shared/services/code/code.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import moment from 'moment'; 
function CreateCodeForm() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const showConfirmation = async () => {
    try {
      await form.validateFields();
      setIsConfirmationVisible(true);
      setConfirmationMessage(getConfirmationMessage());
    } catch (error) {
      // Validation failed, do nothing
    }
  };

  const hideConfirmation = () => {
    setIsConfirmationVisible(false);
  };

  const handleConfirm = () => {
    form.submit();
  };

  const handleSubmit = (values: any) => {
    const formattedDate = values.expirationDate.format("MM-DD-YYYY");
    setIsConfirmationVisible(false);  
    const value = {
      code: values.code,
      expirationDate: values.expirationDate
    }
    addCode(value).then((resp) => {
      toast.success("Code saved successfully.");
      // dispatch({ action: "REFRESH_LIST" });
      history.push("/codes");
      form.resetFields();
  })
  .catch((err) => {
      console.log("🚀 ~ err", err);
  });
  
  };

  const handleFinishFailed = () => {
    setIsConfirmationVisible(false); // Close the confirmation dialog on validation failure
  };

  const handleCancel = () => {
    form.resetFields();
  };

  const getConfirmationMessage = () => {
    const values = form.getFieldsValue();
    const formattedDate = values.expirationDate.format("MM/DD/YYYY");
    return `Are you sure you would like to create a new Paywall Code of ${values.code} with an expiration date of ${formattedDate}?`;
  };

  const disabledDate = (current: any) => {
    // Disable dates before today
    return current && current < moment().startOf('day');
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-1/4 p-4 bg-white">
        <div className="text-2xl mb-4">Create Code</div>
        <Form
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={handleFinishFailed}
        >
          <div className="mb-3">
            <label htmlFor="code" className="block font-bold mb-1">
              Type new code
            </label>
            <Form.Item
              name="code"
              rules={[
                { required: true, message: 'Please enter a new code' },
                { max: 20, message: 'New code cannot exceed 20 characters' }
              ]}
            >
              <Input id="code" style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div className="mb-3">
            <label htmlFor="expirationDate" className="block font-bold mb-1">
              Set Expiration date
            </label>
            <Form.Item
              name="expirationDate"
              rules={[{ required: true }]}
            >
              <DatePicker id="expirationDate" style={{ width: "100%" }} format="MM-DD-YYYY"
              disabledDate={disabledDate} />
            </Form.Item>
          </div>
          <div className="flex justify-center mt-4">
            <Button
              className="mr-2"
              type="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="ml-2"
              type="primary"
              onClick={showConfirmation}
            >
              Confirm
            </Button>
          </div>
        </Form>

        <Modal
        visible={isConfirmationVisible}
        onCancel={hideConfirmation}
        onOk={handleConfirm}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p className="pt-5">{confirmationMessage}</p>
      </Modal>
      </div>
    </div>
  );
}

export default CreateCodeForm;
