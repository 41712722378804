import React, { useContext, useEffect, useReducer, useState } from "react";
import { Button } from "antd";
import styles from "./../user/index.module.less";
import {
    Link,
    Route,
    Switch,
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import ChannelForm from "../channel/Form";
import Explorer from "./Explorer";
import {
    ChannelListContext,
    getChannels,
} from "../../shared/services/channel/channel.service";
import EditContent from "./Edit";
import SeeAll from "./SeeAll";
import { AuthContext } from "../../shared/context/auth";

const Content = () => {
    const history = useHistory();

    const { path } = useRouteMatch();

    const { authState } = useContext(AuthContext);

    const [channels, setChannels] = useState<any>([]);

    const [refreshChannelList, setRefreshChannelList] = useState(0);

    const [channelState, dispatch] = useReducer(
        (currentState: any, payload: any) => {
            if (payload.action === "REFRESH_LIST") {
                setRefreshChannelList((currentState: number) => {
                    return currentState + 1;
                });
            }

            if (payload.action === "UPDATE_LIST") {
                return {
                    channels: payload.data,
                };
            }
            return {};
        },
        {}
    );

    const [selectedChannel, setSelectedChannel] = useState("");

    useEffect(() => {
        getChannels()
            .then((resp) => {
                setChannels((currentState: any) => {
                    return resp.response.data;
                });

                dispatch({ action: "UPDATE_LIST", data: resp.response.data });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [refreshChannelList]);

    useEffect(() => {
        setSelectedChannel((currentState) => {
            if (!currentState) {
                let urlChunk = window.location.href.split("content/")[1];
                return urlChunk?.split("/")[0];
            }
            return "";
        });
    }, []);

    useEffect(() => {
        if (authState.user.role.name === "Content Manager") {
            history.push("/");
        }
    }, []);

    return (
        <ChannelListContext.Provider value={{ dispatch, channelState }}>
            <div className={"space-y-6"}>
                <div className="flex justify-between items-center">
                    <div className={"text-2xl"}>Content</div>
                    <div>
                        <Button type="primary">
                            <Link to={`${path}/form`}>Add New Content</Link>
                        </Button>
                    </div>
                </div>

                <div className={"flex gap-6"}>
                    <div className={"w-1/4 space-y-4"}>
                        <div className={styles.listItem + " border-dashed"}>
                            <Link to={`${path}/channel-form`}>
                                Create a new Channel
                            </Link>
                        </div>
                        {channels.map((channel: any) => {
                            return (
                                <Link
                                    to={`${path}/${channel.id}`}
                                    className={styles.listItem}
                                    key={channel.id}
                                >
                                    <div
                                        className={`text-base ${
                                            selectedChannel === channel.id &&
                                            styles.active
                                        }`}
                                        onClick={() => {
                                            setSelectedChannel(channel.id);
                                        }}
                                    >
                                        {channel.name}
                                    </div>
                                    <Link
                                        to={`${path}/channel-form/${channel.id}`}
                                        className={styles.edit}
                                    >
                                        Edit
                                    </Link>
                                </Link>
                            );
                        })}
                    </div>
                    <div className={"bg-primary-gray rounded p-6 w-3/4"}>
                        <Switch>
                            <Route
                                exact
                                path={`${path}/form`}
                                component={StepOne}
                            />
                            <Route
                                exact
                                path={`${path}/description`}
                                component={StepTwo}
                            />
                            <Route
                                exact
                                path={`${path}/channel-form/:id?`}
                                component={ChannelForm}
                            />
                            <Route
                                exact
                                path={`${path}/:channelId`}
                                component={Explorer}
                            />
                            <Route
                                exact
                                path={`${path}/:contentId/edit`}
                                component={EditContent}
                            />
                            <Route
                                exact
                                path={`${path}/:channelId/all`}
                                component={SeeAll}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </ChannelListContext.Provider>
    );
};

export default Content;
