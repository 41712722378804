import React, { useState } from "react";
import { Form, Select, Button } from "antd";
import MultipleFileUpload from "../../shared/components/MultipleFileUpload";
import ChannelInput from "../../shared/components/ChannelInput";
import { useHistory } from "react-router";

const StepOne = () => {
    const [triggerUpload, setTriggerUpload] = useState(false);

    const [uploading, setUploading] = useState(false);

    const history = useHistory();

    return (
        <div className={"w-2/4"}>
            <Form
                name="ContentForm"
                layout="vertical"
                onFinish={(values) => {
                    localStorage.setItem(
                        "newContentFormData",
                        JSON.stringify(values)
                    );
                    setTriggerUpload(true);
                    setUploading(true);
                }}
            >
                <Form.Item label="File(s)">
                    <MultipleFileUpload
                        triggerUpload={triggerUpload}
                        onUploadComplete={(ev: any) => {
                            setUploading(false);
                            localStorage.setItem(
                                "newContentFilesData",
                                JSON.stringify(ev)
                            );
                            history.push("./description");
                        }}
                    />
                </Form.Item>

                <Form.Item name="channels" label="Channels">
                    <ChannelInput defaultValue={[]} />
                </Form.Item>

                <Form.Item
                    name="tags"
                    label="Tags"
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Add tags"
                        allowClear
                        mode="tags"
                        notFoundContent={
                            <div>
                                Type the keyword and press enter to create a new
                                tag.
                            </div>
                        }
                    ></Select>
                </Form.Item>

                <Form.Item>
                    <div className="flex gap-4">
                        <div className="flex-1">&nbsp;</div>
                        <Button className={"flex-1"} htmlType="reset">
                            Cancel
                        </Button>
                        <Button
                            className={"flex-1"}
                            type="primary"
                            htmlType="submit"
                            loading={uploading}
                        >
                            {uploading ? "Uploading" : "Next"}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default StepOne;
