import React, { useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import ContentIcon from "../../shared/components/ContentIcon";
import { ChannelListContext } from "../../shared/services/channel/channel.service";
import { getAllContents as getAllContentsByChannelId } from "../../shared/services/content/content.service";
import { uniqBy } from "lodash";

const SeeAll = () => {
    const queryParams = new URLSearchParams(window.location.search);

    const { channelState } = useContext(ChannelListContext);

    const [channel, setChannel] = useState<any>();

    const { params } = useRouteMatch<{ channelId: string }>();

    const [type] = useState(queryParams.get("type") || "VIDEO");

    const [search] = useState(queryParams.get("search") || "");

    const [contents, setContents] = useState<any>([]);

    const [paginationParams, setPaginationParams] = useState({
        skip: 0,
        limit: 20,
    });

    const [pageNumber, setPageNumber] = useState(1);

    const [hasNextPage, setHasNextPage] = useState(true);

    useEffect(() => {
        const scrollCallback = (ev: any) => {
            if (
                window.innerHeight + window.scrollY >=
                    document.body.scrollHeight &&
                hasNextPage
            ) {
                setPaginationParams((currentState) => {
                    return {
                        ...currentState,
                        skip: pageNumber * currentState.limit,
                    };
                });
            }
        };

        if (params.channelId) {
            if (channelState.channels) {
                setChannel((currentState: any) => {
                    const found = channelState.channels.find(
                        (x: any) => x.id === params.channelId
                    );
                    return { ...found };
                });
            }

            window.addEventListener("scroll", scrollCallback);
        }

        return () => {
            window.removeEventListener("scroll", scrollCallback, false);
        };
    }, [params]);

    useEffect(() => {
        getContents();
    }, [paginationParams]);

    const getContents = () => {
        getAllContentsByChannelId(
            params.channelId,
            type,
            search,
            paginationParams
        ).then((resp) => {
            setContents((currentState: any) => {
                return uniqBy(
                    [...currentState, ...resp.response.data],
                    (x) => x.id
                );
            });

            setHasNextPage(() => {
                return resp.response.data.length !== 0;
            });

            setPageNumber((currentState) => {
                return currentState + 1;
            });
        });
    };

    return (
        <div className={"space-y-4"}>
            <div className={"text-xl"}>{channel?.name}</div>

            <div className={"grid grid-cols-5 gap-4"}>
                {contents.map((content: any) => {
                    return (
                        <Link
                            key={content.id}
                            className={"border  border-secondary-gray rounded"}
                            to={`/content/${content.id}/edit`}
                        >
                            <div
                                className={
                                    "aspect-w-1 aspect-h-1 bg-secondary-gray w-full bg-center bg-no-repeat "
                                }
                                style={{
                                    backgroundImage: `url(${content.cover.url})`,
                                }}
                            >
                                <ContentIcon type={content.media.type} />
                            </div>
                            <div className={"p-2 bg-primary-white"}>
                                {content.title}
                            </div>
                        </Link>
                    );
                })}
            </div>

            {!hasNextPage ? <div>End of contents!</div> : ""}
        </div>
    );
};

export default SeeAll;
