export const TagReducer = (state: any, action: any) => {
    switch (action.type) {
        case "INITIALIZE":
            return action.data;

        case "CHECKED":
            return state.map((x: any) => {
                if (x.id == action.tagId) {
                    x.checked = true;
                }
                return x;
            });

        case "UNCHECKED":
            return state.map((x: any) => {
                if (x.id == action.tagId) {
                    x.checked = false;
                }
                return x;
            });

        default:
            return [];
    }
};
