import React, { useContext, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import { AuthContext } from "../../shared/context/auth";
import Login from "./Login";
import Register from "./Register";
import logoImage from "./../../../assets/images/logo.png";
import { Card, Row } from "antd";
import ForgotPassword from "./ForgotPassword";

const Auth = () => {
    const { path } = useRouteMatch();

    const { authState } = useContext(AuthContext);

    const history = useHistory();

    useEffect(() => {
        if (authState.token && authState.user) {
            history.push("/");
        }
    }, [authState]);

    return (
        <div className={"w-screen h-screen flex items-center justify-center"}>
            {!authState.loading && (
                <Card style={{ width: 400 }} className={"shadow-2xl"}>
                    <Row justify={"center"}>
                        <img src={logoImage} alt="Logo" className={"w-2/4"} />
                    </Row>

                    <div className="mt-6">
                        <Switch>
                            <Route
                                exact
                                path={`${path}/register`}
                                component={Register}
                            />
                            <Route
                                exact
                                path={`${path}/forgot-password`}
                                component={ForgotPassword}
                            />
                            <Route path={`${path}`} component={Login} />
                        </Switch>
                    </div>
                </Card>
            )}
        </div>
    );
};

export default Auth;
