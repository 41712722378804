import gql from "graphql-tag";

export const codeFieldsFragment = gql`
    fragment codeFields on Code {
        id
        code
        expirationDate
        createdAt
        updatedAt
    }
`;

export const codesQuery = gql`
    query Codes($pagingInput: PagingInput) {
        codes(pagination: $pagingInput) {
            data {
                id
                code
                expirationDate
                user {
                    id
                    firstName
                    lastName
                    email
                }
                createdAt
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }
`;
 

export const createCodeMutation = gql`
    mutation CreateCode($createCodeInput: CreateCodeInput!) {
        createCode(createCodeInput: $createCodeInput) {
            ...codeFields
        }
    }

    ${codeFieldsFragment}
`;

export const removeCodeMutation = gql`
    mutation RemoveCode($id: String!) {
        removeCode(id: $id) {
            ...codeFields
        }
    }

    ${codeFieldsFragment}
`;

export const updateCodeMutation = gql`
    mutation UpdateCode($updateCodeInput: UpdateCodeInput!) {
        updateCode(updateCodeInput: $updateCodeInput) {
            ...codeFields
        }
    }

    ${codeFieldsFragment}
`;
