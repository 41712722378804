import apolloClient from "../../../config/apolloClient";
import {
    contentQuery,
    contentsQuery as groupedContentsQuery,
    createContentMutation,
    listContentQuery,
    removeContentMutation,
    updateContentMutation,
    uploadProgresMutation,
} from "./content.gql";

export const createContentRequest = async (formData: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createContentMutation,
        variables: {
            createContentInput: {
                ...formData,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createContent };
};

export const updateContentRequest = async (formData: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: updateContentMutation,
        variables: {
            updateContentInput: {
                ...formData,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createContent };
};

export const getContentsByChannelId = async (
    channel: string,
    search: string = ""
) => {
    const { data, errors } = await apolloClient.query({
        query: groupedContentsQuery,
        variables: {
            filters: {
                channel,
                search,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.groupedContent };
};

export const getAllContents = async (
    channelId: string,
    mediaType: string,
    search: string = "",
    pagination: any
) => {
    const { data, errors } = await apolloClient.query({
        query: listContentQuery,
        variables: {
            filters: {
                mediaType: mediaType.toUpperCase(),
                search,
                channels: [channelId],
            },
            pagination,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.listContent };
};

export const getContentById = async (id: string) => {
    const { data, errors } = await apolloClient.query({
        query: contentQuery,
        variables: {
            id,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.content };
};

export const deleteContent = async (id: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: removeContentMutation,
        variables: {
            id,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.content };
};

export const uploadProgress = async (vimeoUploadLink: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: uploadProgresMutation,
        variables: {
            vimeoUploadLink,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.uploadProgress };
};

export const getSocialMedias = () => {
    return [
        "Facebook Post",
        "Facebook Stories",
        "Instagram Post",
        "Instagram Reels",
        "Instagram Stories",
        "Pinterest Pin",
        "TikTok",
        "Vimeo",
        "Youtube",
    ];
};
