import React, { useEffect, useReducer, useState } from "react";
import { Form, Checkbox, Button, DatePicker } from "antd";
import ChannelInput from "../../shared/components/ChannelInput";
import styles from "./index.module.less";
import { getTags } from "../../shared/services/tag/tag.service";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { TagReducer } from "./TagReducer";
import moment from "moment";
import {
    getReports,
    getReportsByContent,
} from "../../shared/services/report/report.service";
import { useHistory } from "react-router-dom";
import { exchangeRefreshTokenRequest } from "../../shared/services/auth/auth.service";

const Dashboard = () => {
    const [reports, setReports] = useState<any>();

    const [selectedChannels, setSelectedChannels] = useState([]);

    const [fetching, setFetching] = useState(false);

    const [currentContent, setCurrentContent] = useState<any>();

    const [contents, setContents] = useState([]);

    const history = useHistory();

    const [dateRange, setDateRange] = useState({
        startDate: moment().startOf("week"),
        endDate: moment().endOf("week"),
    });

    const [tags, dispatch] = useReducer(TagReducer, [], () => {
        getTags().then((resp) => {
            dispatch({
                type: "INITIALIZE",
                data: resp.response,
            });
        });
    });

    useEffect(() => {
        if (currentContent) {
            const payload = {
                id: currentContent.id,
                filter: {
                    ...dateRange,
                },
            };

            getReportsByContent(payload)
                .then((resp) => {
                    setReports(resp.response);
                    setFetching(false);
                })
                .catch((err) => {
                    console.log(
                        "🚀 ~ file: index.tsx ~ line 59 ~ getReports ~ err",
                        err
                    );
                });
        }
    }, [currentContent]);

    const onSubmit = (values: any) => {
        setFetching(true);
        let payload: any = {
            channels: selectedChannels,
        };

        const selectedTags = tags
            .filter((x: any) => x.checked)
            .map((y: any) => y.id);

        if (selectedTags.length) {
            payload = {
                ...payload,
                tags: selectedTags,
            };
        }

        if (values.mediaType) {
            payload = {
                ...payload,
                mediaType: values.mediaType,
            };
        }

        if (values.subCategory) {
            payload = {
                ...payload,
                subCategory: values.subCategory,
            };
        }

        if (values.dateRange) {
            payload = {
                ...payload,
                startDate: values.dateRange[0],
                endDate: values.dateRange[1],
            };
            setDateRange({
                startDate: values.dateRange[0],
                endDate: values.dateRange[1],
            });
        }

        getReports(payload)
            .then((resp) => {
                setReports(resp.response);
                setFetching(false);
                setCurrentContent(null);
                setContents(resp.response.contents);
            })
            .catch((err) => {
                console.log(
                    "🚀 ~ file: index.tsx ~ line 59 ~ getReports ~ err",
                    err
                );
            });
    };

    return (
        <div className="container">
            <div className="flex gap-10">
                <div className="w-1/4 border-r -6 pr-4">
                    <Form onFinish={onSubmit}>
                        <div className="space-y-4">
                            <div className="text-lg">Filters</div>

                            <div className="space-y-2">
                                <div className="font-bold">When</div>
                                <Form.Item name="dateRange">
                                    <DatePicker.RangePicker
                                        ranges={{
                                            Today: [moment(), moment()],
                                            Week: [
                                                moment().startOf("week"),
                                                moment().endOf("week"),
                                            ],
                                            Month: [
                                                moment().startOf("month"),
                                                moment().endOf("month"),
                                            ],
                                        }}
                                        defaultValue={[
                                            moment().startOf("week"),
                                            moment().endOf("week"),
                                        ]}
                                    ></DatePicker.RangePicker>
                                </Form.Item>
                            </div>

                            <div className="space-y-2">
                                <div className="font-bold">Channels</div>
                                <div>
                                    <ChannelInput
                                        onChange={(channelIds: any) => {
                                            setSelectedChannels(channelIds);
                                        }}
                                        grids={1}
                                        defaultValue={[]}
                                    />
                                </div>
                            </div>

                            <div className="space-y-2">
                                <div className="font-bold">Type</div>
                                <div>
                                    <Form.Item
                                        name="subCategory"
                                        className="mb-0"
                                    >
                                        <Checkbox.Group>
                                            <div>
                                                <Checkbox value="STIRY_STORY">
                                                    Stiry+ Original Story
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="SHORT">
                                                    Short
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="VIDEO_PODCAST">
                                                    Video Podcast
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="REELS">
                                                    Reels
                                                </Checkbox>
                                            </div>
                                        </Checkbox.Group>
                                    </Form.Item>
                                    <Form.Item name="mediaType">
                                        <Checkbox.Group>
                                            <div>
                                                <Checkbox value="AUDIO">
                                                    Audio
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox value="IMAGE">
                                                    Image
                                                </Checkbox>
                                            </div>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="space-y-2 ">
                                <div className="font-bold">Tags</div>
                                <div className="max-h-32 overflow-y-auto">
                                    {tags?.map((tag: any) => {
                                        return (
                                            <CheckableTag
                                                key={tag.id}
                                                checked={tag.checked}
                                                onChange={(ev) => {
                                                    dispatch({
                                                        type: ev
                                                            ? "CHECKED"
                                                            : "UNCHECKED",
                                                        tagId: tag.id,
                                                    });
                                                }}
                                            >
                                                {tag.name}
                                            </CheckableTag>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="flex justify-end py-8 gap-4">
                                <Button
                                    ghost
                                    size="large"
                                    type="primary"
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                >
                                    Apply
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>

                <div className="w-3/4 space-y-6">
                    <div className="text-lg flex gap-4">
                        Performance Report{" "}
                        {currentContent && (
                            <>
                                <div>&gt;</div>
                                <div>{currentContent.title}</div>
                            </>
                        )}
                    </div>
                    {!fetching && reports && (
                        <>
                            <div className="grid gap-10 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                <div className="aspect-w-3 aspect-h-2 border text-center rounded">
                                    <div className="flex items-center flex-col justify-center">
                                        <div className="text-4xl font-bold">
                                            {reports?.totalContentViews}
                                        </div>
                                        <div>Total Views</div>
                                    </div>
                                </div>
                                <div className="aspect-w-3 aspect-h-2 border text-center rounded">
                                    <div className="flex items-center flex-col justify-center">
                                        <div className="text-4xl font-bold">
                                            {reports?.totalContentDownloads}
                                        </div>
                                        <div>Downloads</div>
                                    </div>
                                </div>

                                <div className="aspect-w-3 aspect-h-2 border text-center rounded">
                                    <div className="flex items-center flex-col justify-center">
                                        <div className="text-4xl font-bold">
                                            {reports?.averageViewDuration?.toFixed() ||
                                                0}
                                            %
                                        </div>
                                        <div>Avg. View Duration</div>
                                    </div>
                                </div>

                                <div className="aspect-w-3 aspect-h-2 border text-center rounded">
                                    <div className="flex items-center flex-col justify-center">
                                        <div className="text-4xl font-bold">
                                            {reports?.avgUserSessionDuration?.toFixed()}
                                            m
                                        </div>
                                        <div>Avg. User Session Duration</div>
                                    </div>
                                </div>

                                <div className="aspect-w-3 aspect-h-2 border text-center rounded">
                                    <div className="flex items-center flex-col justify-center">
                                        <div className="text-4xl font-bold">
                                            {reports?.avgViewsUserPerSession}
                                        </div>
                                        <div>Avg. Views Per Session</div>
                                    </div>
                                </div>
                            </div>
                            {contents?.length ? (
                                <div>
                                    <div className="text-lg pb-4">Contents</div>
                                    <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                                        {contents.map(
                                            (content: any, index: number) => {
                                                return (
                                                    <div
                                                        key={content.id}
                                                        className="truncate cursor-pointer hover:text-primary-red"
                                                        onClick={() =>
                                                            setCurrentContent(
                                                                content
                                                            )
                                                        }
                                                    >
                                                        {content.title}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            ) : null}

                            {reports?.wordsSearch.length ? (
                                <div>
                                    <div className="text-lg pb-4">
                                        Most Searched Words
                                    </div>
                                    <div className="space-y-2">
                                        <div className="flex">
                                            <div className="flex-1"></div>
                                            <div className="flex-1 font-bold">
                                                Counts
                                            </div>
                                        </div>
                                        {reports.wordsSearch.map(
                                            (searchTerm: any) => {
                                                return (
                                                    <div
                                                        key={searchTerm.word}
                                                        className="flex"
                                                    >
                                                        <div className="flex-1">
                                                            {searchTerm.word}
                                                        </div>
                                                        <div className="flex-1">
                                                            {searchTerm.count}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
