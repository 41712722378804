import React, { useEffect, useState } from "react";
import { getChannels } from "../services/channel/channel.service";
import { Checkbox } from "antd";

const ChannelInput = ({ onChange, defaultValue = [], grids = 2 }: any) => {
    const [channels, setChannels] = useState([]);

    const [checkAll, setCheckAll] = useState(false);

    const [checkedList, setCheckedList] = useState<any>([]);

    useEffect(() => {
        getChannels({ isCMS: true })
            .then((resp) => {
                setChannels(resp.response.data);

                if (defaultValue.length) {
                    setCheckedList(() => {
                        return [...defaultValue];
                    });

                    setCheckAll(
                        defaultValue.length === resp.response.data.length
                    );
                }
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [defaultValue]);

    useEffect(() => {
        onChange(checkedList);
    }, [checkedList]);

    return (
        <div>
            <Checkbox
                onChange={(ev) => {
                    setCheckAll(ev.target.checked);
                    setCheckedList(() => {
                        if (ev.target.checked) {
                            return [...channels.map((x: any) => x.id)];
                        } else {
                            return [];
                        }
                    });
                }}
                checked={checkAll}
            >
                Select all
            </Checkbox>
            <Checkbox.Group
                value={checkedList}
                onChange={(list) => {
                    setCheckedList(() => {
                        return [...list];
                    });

                    setCheckAll(list.length === channels.length);
                }}
                options={channels.map((x: any) => {
                    return {
                        label: x.name,
                        value: x.id,
                    };
                })}
                className={"grid grid-cols-" + grids}
            />
        </div>
    );
};

export default ChannelInput;
