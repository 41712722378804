import React, { useContext } from "react";
import "./App.less";
import { AuthContext, AuthProvider } from "./shared/context/auth";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./modules/dashboard";
import NotFound from "./shared/components/NotFound";
import ProtectedRoute from "./shared/components/ProtectedRoute";
import Auth from "./modules/auth";
import User from "./modules/user";
import Content from "./modules/content";
import Codes from "./modules/code"
import CreateCodeForm from "./modules/code/create-code";
import Settings from "./modules/settings";
import Dashboard_V2 from "./modules/dashboard-v2";

function App() {
    return (
        <AuthProvider>
            <Router>
                <Switch>
                    <ProtectedRoute exact path="/" component={Dashboard} />
                    <ProtectedRoute exact path="/dashboard-v2" component={Dashboard_V2} />

                    <ProtectedRoute path="/content" component={Content} />

                    <ProtectedRoute path="/users" component={User} />

                    <ProtectedRoute path="/codes" component={Codes} />
                    <ProtectedRoute path="/create-code" component={CreateCodeForm} />
                    <ProtectedRoute path="/settings" component={Settings} />

                    <Route path="/auth" component={Auth} />

                    <Route path="*/*" component={NotFound} />
                </Switch>
                <ToastContainer />
            </Router>
        </AuthProvider>
    );
}

export default App;
