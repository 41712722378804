import gql from "graphql-tag";
import { userFieldsFragment } from "../user/user.gql";

export const loginMutation = gql`
    mutation Login($input: LoginInput!) {
        Login(input: $input) {
            ...loginFields
        }
    }

    fragment loginFields on LoginResponse {
        access_token
        refresh_token
        user {
            ...userFields
        }
    }

    ${userFieldsFragment}
`;

export const registerMutation = gql`
    mutation Register($input: UserCreate!) {
        Register(input: $input) {
            ...messageFields
        }
    }

    fragment messageFields on MessageResponse {
        message
    }
`;

export const exchangeRefreshTokenMutation = gql`
    query ExchangeRefreshToken($input: ExchangeRefreshTokenInput!) {
        ExchangeRefreshToken(input: $input) {
            access_token
            refresh_token
        }
    }
`;
