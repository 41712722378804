import apolloClient from "../../../config/apolloClient";
import { getReportsByContentQuery, getReportsQuery } from "./report.gql";

export const getReports = async (filter: any) => {
    const { data, errors } = await apolloClient.query({
        query: getReportsQuery,
        variables: {
            filter,
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.reports };
};

export const getReportsByContent = async (filter: any) => {
    const { data, errors } = await apolloClient.query({
        query: getReportsByContentQuery,
        variables: {
            ...filter
        },
    });

    if (errors) {
        return { errors };
    }

    return { response: data.reportByContent };
};
