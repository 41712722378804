import gql from "graphql-tag";

export const contentFieldsFragment = gql`
    fragment contentFields on Content {
        id
        title
        subtitle
        featured
        featuredUntil
        channels {
            id
            name
        }
        tags {
            id
            name
        }
        cover {
            id
            url
            type
            createdAt
            updatedAt
        }
        coverFeaturedContent {
            id
            url
            type
            createdAt
            updatedAt
        }
        media {
            id
            url
            type
            size
            createdAt
            updatedAt
        }
        socialMediaUsage
        socialCopy
        length
        search
        alsoWatched
        createdAt
        updatedAt
        subCategory
        isDownloadable
        coverOrientation
        description
    }
`;

export const contentFieldsMinimalFragment = gql`
    fragment contentFieldsMinimal on Content {
        id
        title
        cover {
            url
        }
        media {
            type
        }
    }
`;

export const createContentMutation = gql`
    mutation CreateContent($createContentInput: CreateContentInput!) {
        createContent(createContentInput: $createContentInput) {
            ...contentFields
        }
    }

    ${contentFieldsFragment}
`;

export const updateContentMutation = gql`
    mutation UpdateContent($updateContentInput: UpdateContentInput!) {
        updateContent(updateContentInput: $updateContentInput) {
            ...contentFields
        }
    }

    ${contentFieldsFragment}
`;

export const contentsQuery = gql`
    query GroupedContent($filters: GroupedContentFilters) {
        groupedContent(filters: $filters) {
            image {
                ...contentFieldsMinimal
            }
            audio {
                ...contentFieldsMinimal
            }
            video {
                ...contentFieldsMinimal
            }
            video_podcast {
                ...contentFieldsMinimal
            }
            short {
                ...contentFieldsMinimal
            }
            stiry_story {
                ...contentFieldsMinimal
            }
            reels {
                ...contentFieldsMinimal
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const listContentQuery = gql`
    query ListContent($pagination: PagingInput, $filters: ContentFilterInput) {
        listContent(pagination: $pagination, filter: $filters) {
            data {
                ...contentFieldsMinimal
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }

    ${contentFieldsMinimalFragment}
`;

export const contentQuery = gql`
    query Content($id: String!) {
        content(id: $id) {
            ...contentFields
        }
    }

    ${contentFieldsFragment}
`;

export const removeContentMutation = gql`
    mutation RemoveContent($id: String!) {
        removeContent(id: $id) {
            ...contentFields
        }
    }

    ${contentFieldsFragment}
`;

export const uploadProgresMutation = gql`
    query uploadProgress($vimeoUploadLink: String!) {
        uploadProgress(vimeoUploadLink: $vimeoUploadLink) {
            progress
            uploadStatus
        }
    }
`;
