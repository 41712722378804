import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth";
import { getCodes } from "../../shared/services/dashboard-v2/dashboard-v2";
import { toast } from "react-toastify";
import logoImage from "./../../../assets/images/loader.gif";
import {
  getReports,
  getReportsByContent,
} from "../../shared/services/report/report.service";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const CustomXAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-45)">
        {payload.value}
      </text>
    </g>
  );
};

const Loader = () => (
  <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
    <img src={logoImage} alt="Loading..." style={{ width: '70px', height: '70px' }} />
  </div>
);

const Dashboard_V2 = () => {

  const [loading, setLoading] = useState(true);

  const formatDuration = (seconds:any) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formattedResult = `${hours}.${minutes}`;
    return formattedResult;
  }
  const history = useHistory();
  const { authState } = useContext(AuthContext);

  const [codes, setCodes] = useState([]);
  const [siteVisitsData, setsiteVisitsData] = useState([]);
  const [averageWatchTimeData, setAverageWatchTime] = useState([]);
  const [refreshChannelList, setRefreshUserList] = useState(0);
  const [selectedCodeInfo, setSelectedCodeInfo] = useState(null);
  const [selectedRange, setSelectedRange] = useState('30 Days');
  const [reportData, setReportData] = useState({
    totalContentViews: 0,
    totalViewDuration: 0,

  });

  useEffect(() => {
    getCodes()
      .then((resp) => {
        setCodes(resp.response.data);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, [refreshChannelList]);

  useEffect(() => {
    if (authState.user.role.name === "Content Manager") {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    // Call handleRangeClick with the initial range when the component mounts
    handleRangeClick(selectedRange);
  }, []); // Empty dependency array ensures it runs only once on mount

  const handleRangeClick = (range: any) => {
    setLoading(true); // Set loading state to true

    setSelectedRange(range);

    const { startDate, endDate } = calculateDateRange(range);

    const payload = {
      channels: [
        "624229752245d9fc082957a9",
        "624229682245d9fc082957a0",
        "6242295a2245d9fc08295797",
        "6242294d2245d9fc0829578e",
        "6242293b2245d9fc08295785",
        "6242291e2245d9fc0829577c",
        "6242290c2245d9fc08295773",
        "618bfeef7ac6d2921792096e",
        "618bfdaa7ac6d2921792094f",
        "618bfb797ac6d2921792087c",
        "618bec5e7ac6d2921792020f",
        "617b2e36ed4106756478c6c1"
    ],
      mediaType: ["AUDIO", "IMAGE"],
      subCategory: ["STIRY_STORY", "SHORT", "VIDEO_PODCAST", "REELS"],
      startDate,
      endDate,
    };

    getReports(payload)
      .then((resp) => {
        const dateAggregator: any = {};
        resp.response.totalContentViewByDate.forEach((item: any) => {
          const formattedDate = moment(item._id).format("MM-DD-YY");
  
          if (!dateAggregator[formattedDate]) {
            dateAggregator[formattedDate] = {
              date: formattedDate,
              views: 0,
              averagePerDay: 0,
            };
          }
  
          dateAggregator[formattedDate].views += item.views;
          dateAggregator[formattedDate].averagePerDay += item.averagePerDay;
        });
  
        // Transform the aggregated data
        const transformedData : any= Object.values(dateAggregator).map((aggItem: any) => ({
          date: aggItem.date,
          value: aggItem.views,
        })).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  
        const transformedAvegData : any = Object.values(dateAggregator).map((aggItem: any) => ({
          date: aggItem.date,
          value: Math.floor((aggItem.averagePerDay % 3600) / 60),
        })).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        setsiteVisitsData(transformedData);
        setAverageWatchTime(transformedAvegData);
        setReportData(resp.response);
      })
      .catch((err) => {
        console.error('Error fetching reports:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const calculateDateRange = (range: any) => {
    const endDate = moment().endOf('day').toISOString();
    let startDate;

    switch (range) {
      case '30 Days':
        startDate = moment().subtract(30, 'days').startOf('day').toISOString();
        break;
      case '60 Days':
        startDate = moment().subtract(60, 'days').startOf('day').toISOString();
        break;
      case '90 Days':
        startDate = moment().subtract(90, 'days').startOf('day').toISOString();
        break;
      default:
        startDate = moment().subtract(30, 'days').startOf('day').toISOString();
    }

    return { startDate, endDate };
  };


  const barChartData = [
    { channel: "Conquering Chronic Illness", viewed: 34 },
    { channel: "Enriching Relationships", viewed: 50 },
    { channel: "Elevating Fitness & Wellness", viewed: 78 },
  ];



  return (
    <div style={{ textAlign: 'center' }}>
      {loading && <Loader />}
      {/* First Row */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flex: 3, fontSize: '2em', textAlign: 'center', paddingLeft: 'calc(100% - 82%)' }}>Stiry+ Dashboard</div>
        <div>
          Date Range: &nbsp;
          <button
            style={{ backgroundColor: selectedRange === '30 Days' ? 'red' : 'transparent' }}
            onClick={() => handleRangeClick('30 Days')}
          >
            30 Days
          </button>
          |
          <button
            style={{ backgroundColor: selectedRange === '60 Days' ? 'red' : 'transparent' }}
            onClick={() => handleRangeClick('60 Days')}
          >
            60 Days
          </button>
          |
          <button
            style={{ backgroundColor: selectedRange === '90 Days' ? 'red' : 'transparent' }}
            onClick={() => handleRangeClick('90 Days')}
          >
            90 Days
          </button>
        </div>
      </div>

      {/* Second Row */}
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', marginTop: '10px' }}>
        {/* First Column */}
        <div style={{ fontSize: '2em', textAlign: 'center' }}>Total Views</div>
        <div style={{ fontSize: '2em', textAlign: 'center' }}>Total Watch Time</div>

        {/* Second Column */}
        <div style={{ backgroundColor: 'rgb(185 185 185 / 37%)', padding: '20px', margin: '0px 70px', textAlign: 'center' }}>
          <div style={{ fontSize: '1.5em' }}>{reportData.totalContentViews}</div>
          <div style={{ fontSize: '1.5em' }}>Views</div>
        </div>
        <div style={{ backgroundColor: 'rgb(185 185 185 / 37%)', padding: '20px', margin: '0px 70px', textAlign: 'center' }}>
          <div style={{ fontSize: '1.5em' }}>{formatDuration(reportData.totalViewDuration)}</div>
          <div style={{ fontSize: '1.5em' }}>Hours</div>
        </div>
      </div>

      {/* Third Row */}
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px', marginTop: '20px' , marginBottom: '20px'}}>
        {/* Single Column */}
        <div style={{ fontSize: '2em', textAlign: 'center' }}>Total Site Visits</div>
        <div style={{ fontSize: '2em', textAlign: 'center' }}>Average Watch Time</div>
        <div style={{ textAlign: 'center' }}>
          <ResponsiveContainer width="100%" height={350}>
            <LineChart data={siteVisitsData} margin={{ top: 20, right: 30, left: 20, bottom: 40 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" label={{ value: 'Date', position: 'insideBottom', offset: -35 }} tick={<CustomXAxisTick />} interval={0} />
              <YAxis label={{ value: 'Site Visits', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div style={{ textAlign: 'center' }}>
          <ResponsiveContainer width="100%" height={350}>
            <LineChart data={averageWatchTimeData} margin={{ top: 20, right: 30, left: 20, bottom: 40 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" label={{ value: 'Date', position: 'insideBottom', offset: -35 }} tick={<CustomXAxisTick />} interval={0} />
              <YAxis label={{ value: 'Watch Time in Minutes', angle: -90, position: 'insideLeft', dx: -1, dy: 50 }} />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>



      {/* Fourth Row - Most Watched Content */}
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '20px', marginTop: '30px', margin: '0px auto', width: '80%' }}>
        <div style={{ gridColumn: 'span 1', textAlign: 'center' }}>
          <img src="https://stiry-space.sfo3.digitaloceanspaces.com/stiry-space/image/9945631677874749383.jpg" alt="Content 1" style={{ width: '100%', height: 'auto' }} />
          <button style={{ backgroundColor: 'lightgray', marginTop: '10px', padding: '10px' }}>39 Views</button>
        </div>
        <div style={{ gridColumn: 'span 1', textAlign: 'center' }}>
          <img src="https://stiry-space.sfo3.digitaloceanspaces.com/stiry-space/image/2791a11677868958670.jpg" alt="Content 1" style={{ width: '100%', height: 'auto' }} />
          <button style={{ backgroundColor: 'lightgray', marginTop: '10px', padding: '10px' }}>35 Views</button>
        </div>
        <div style={{ gridColumn: 'span 1', textAlign: 'center' }}>
          <img src="https://stiry-space.sfo3.digitaloceanspaces.com/stiry-space/image/428bfc1677880227559.jpg" alt="Content 1" style={{ width: '100%', height: 'auto' }} />
          <button style={{ backgroundColor: 'lightgray', marginTop: '10px', padding: '10px' }}>27 Views</button>
        </div>
        <div style={{ gridColumn: 'span 1', textAlign: 'center' }}>
          <img src="https://stiry-space.sfo3.digitaloceanspaces.com/stiry-space/image/52679d1677873702080.jpg" alt="Content 1" style={{ width: '100%', height: 'auto' }} />
          <button style={{ backgroundColor: 'lightgray', marginTop: '10px', padding: '10px' }}>20 Views</button>
        </div>
        <div style={{ gridColumn: 'span 1', textAlign: 'center' }}>
          <img src="https://stiry-space.sfo3.digitaloceanspaces.com/stiry-space/image/26491e1677864077240.jpg" alt="Content 1" style={{ width: '100%', height: 'auto' }} />
          <button style={{ backgroundColor: 'lightgray', marginTop: '10px', padding: '10px' }}>17 Views</button>
        </div>
      </div>
      {/* Fifth Row */}
      <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '10px', marginTop: '20px' }}>
        {/* Single Column */}
        <div style={{ fontSize: '2em', textAlign: 'left', paddingLeft: '8%' }}>Most Watched Channels</div>
        <div style={{ fontSize: '2em', textAlign: 'center' }}></div>
        <div style={{ textAlign: 'center' }}>
          <ResponsiveContainer width="100%" height={350}>
            <BarChart data={barChartData} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" label={{ value: 'Times Viewed', position: 'bottom', offset: -5 }} />
              <YAxis dataKey="channel" type="category" width={100} />
              <Tooltip />
              <Bar dataKey="viewed" fill="#eb2137" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div style={{ fontSize: '2em', textAlign: 'center' }}></div>
      </div>

      {/* Rest of your content goes here */}
    </div>
  );
};

export default Dashboard_V2;
