import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthActions, AuthContext } from "../context/auth";
import DashboardLayout from "../layouts/DashboardLayout";

const ProtectedRoute = ({ component, ...restOfProps }: any) => {
    const { authState, dispatch } = useContext(AuthContext);

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (!authState.loading) {
            setIsReady(true);
        }

        if (authState?.user?.role?.name?.toLowerCase() === "user") {
            toast.error(
                "You do not have permission to perform this operation."
            );
            dispatch({
                action: AuthActions.LOGOUT,
                data: {},
            });
        }
    }, [authState]);

    return (
        <>
            {!isReady ? (
                <div className="">Loading...</div>
            ) : (
                <>
                    {authState.token && authState.user ? (
                        <DashboardLayout>
                            <Route {...restOfProps} component={component} />
                        </DashboardLayout>
                    ) : (
                        <Redirect to={{ pathname: "/auth" }} />
                    )}
                </>
            )}
        </>
    );
};

export default ProtectedRoute;
