import { createContext } from "react";
import apolloClient from "../../../config/apolloClient";
import {
    createUserMutation,
    removeUserMutation,
    updateUserMutation,
    userQuery,
    usersQuery,
} from "./user.gql";

export const UserListContext = createContext<any>({});

export const getUsers = async () => {
    const { data, errors } = await apolloClient.query({
        query: usersQuery,
        variables: {
            pagingInput: {
                limit: 0,
                orderBy: "createdAt",
                orderByDir: "DESC",
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.users };
};

export const getUserById = async (id: string) => {
    const { data, errors } = await apolloClient.query({
        query: userQuery,
        variables: {
            id,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.user };
};

export const addUser = async (formData: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: createUserMutation,
        variables: {
            createUserInput: {
                ...formData,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createUser };
};

export const updateUser = async (formData: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: updateUserMutation,
        variables: {
            updateUserInput: {
                ...formData,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.updateUser };
};

export const deleteUser = async (id: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: removeUserMutation,
        variables: {
            id,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.user };
};
