import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { getValidToken } from "../shared/services/auth/auth.service";

const axiosClient = axios.create({
    baseURL: `${process.env[`REACT_APP_API_URL`]}/v1`,
});

axiosClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
    let token = await getValidToken();

    config.headers = {
        Authorization: token ? `Bearer ${token.access_token}` : null,
    };

    return config;
});

axiosClient.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        return response.data;
    },
    (error) => {
        const errorMessage =
            error?.response?.data?.message || "Failed to complete the request";
        toast.error(errorMessage);

        throw error;
    }
);

export default axiosClient;
