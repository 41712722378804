import { createContext } from "react";
import apolloClient from "../../../config/apolloClient";
import {
    codesQuery,
    createCodeMutation,
    removeCodeMutation,
    updateCodeMutation
} from "./code.gql";

export const CodeListContext = createContext<any>({});

export const getCodes = async () => {
    const { data, errors } = await apolloClient.query({
        query: codesQuery,
        variables: {
            pagingInput: {
                limit: 0,
                orderBy: "createdAt",
                orderByDir: "DESC",
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.codes };
};

export const addCode = async (formData: any) => {
    console.log(formData,'formData')
    const { data, errors } = await apolloClient.mutate({
        mutation: createCodeMutation,
        variables: {
            createCodeInput: {
                ...formData,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.createCode };
};


export const deleteCode = async (id: string) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: removeCodeMutation,
        variables: {
            id,
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.code };
};
export const updateCode = async (formData: any) => {
    const { data, errors } = await apolloClient.mutate({
        mutation: updateCodeMutation,
        variables: {
            updateCodeInput: {
                ...formData,
            },
        },
    });

    if (errors) {
        throw errors;
    }

    return { response: data.updateCode };
};
