import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import {
    deleteContent,
    getContentById,
    getSocialMedias,
    updateContentRequest,
} from "../../shared/services/content/content.service";
import MediaPreview from "./MediaPreview";
import {
    Button,
    DatePicker,
    Form,
    Input,
    Popconfirm,
    Radio,
    Select,
    Switch,
} from "antd";
import FileUpload from "../../shared/components/FileUpload";
import ChannelInput from "../../shared/components/ChannelInput";
import moment from "moment";
import { toast } from "react-toastify";
import { QuestionCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

const EditContent = () => {
    const history = useHistory();

    const { params } = useRouteMatch<{ contentId: string }>();

    const [contentForm] = Form.useForm();

    const [content, setContent] = useState<any>();

    const [triggerUpload, setTriggerUpload] = useState(false);

    const [triggerUploadContent, setTriggerUploadContent] = useState(false);

    const [formData, setFormData] = useState<any>({
        id: params.contentId,
    });

    const [saveContent, setSaveContent] = useState(0);

    const [duration, setDuration] = useState(0);

    const [isFeatured, setIsFeatured] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    const [triggerFeaturedCoverUpload, setTriggerFeaturedCoverUpload] =
        useState(false);

    const socialMedias = getSocialMedias();

    useEffect(() => {
        if (params.contentId) {
            getContentDetails();
        }
    }, [params]);

    const getContentDetails = () => {
        getContentById(params.contentId).then((resp) => {
            const tempContent = resp.response;
            setContent(tempContent);
            contentForm.setFieldsValue({
                title: tempContent.title,
                subtitle: tempContent.subtitle,
                description: tempContent.description,
                featuredUntil:
                    tempContent.featuredUntil &&
                    moment(tempContent.featuredUntil),
                tags: tempContent.tags.map((x: any) => x.name),
                socialMediaUsage: tempContent.socialMediaUsage,
                subCategory: tempContent.subCategory,
                coverOrientation: tempContent.coverOrientation?.toUpperCase(),
            });
            setIsFeatured(tempContent.featured);
        });
    };

    useEffect(() => {
        if (saveContent > 0) {
            updateContentRequest({
                ...formData,
                subCategory: formData.subCategory?.toUpperCase(),
            })
                .then((resp) => {
                    setIsSaving(false);
                    toast.success("Content updated successfully.");
                    history.push("/content/" + formData.channels[0]);
                })
                .catch((err) => {
                    setIsSaving(false);
                    console.log("🚀 ~ err", err);
                });
        }
    }, [saveContent]);

    return (
        <>
            {content && (
                <div className={"flex gap-16"}>
                    <div className={"flex-1"}>
                        <div className="hidden">{content?.length}</div>
                        <Form
                            name="ContentForm"
                            layout="vertical"
                            onFinish={(values) => {
                                setIsSaving(true);
                                if (
                                    content.media.type.toLowerCase() === "image"
                                ) {
                                    setFormData((currentState: any) => {
                                        return {
                                            ...currentState,
                                            ...values,
                                            id: params.contentId,
                                            media: content.media.id,
                                            cover: content.cover.id,
                                            length:
                                                Number(duration) ||
                                                content.length ||
                                                100,
                                        };
                                    });
                                    setSaveContent((currentState) => {
                                        return currentState + 1;
                                    });
                                } else {
                                    setFormData((currentState: any) => {
                                        return {
                                            ...currentState,
                                            ...values,
                                            id: params.contentId,
                                            media: content.media.id,
                                            length:
                                                Number(duration) ||
                                                content.length ||
                                                100,
                                        };
                                    });
                                    setTriggerUpload(true);
                                }
                            }}
                            form={contentForm}
                        >
                            {content.media.type.toLowerCase() !== "image" ? (
                                <>
                                    <Form.Item name="cover" label="Cover Image">
                                        <FileUpload
                                            triggerUpload={triggerUpload}
                                            onUploadComplete={(media: any) => {
                                                setTriggerUpload(false);
                                                if (media) {
                                                    setFormData(
                                                        (currentState: any) => {
                                                            return {
                                                                ...currentState,
                                                                cover:
                                                                    media?._id ||
                                                                    media?.id,
                                                            };
                                                        }
                                                    );
                                                }

                                                if (isFeatured) {
                                                    setTriggerFeaturedCoverUpload(
                                                        true
                                                    );
                                                } else {
                                                    setTriggerUploadContent(
                                                        true
                                                    );
                                                }
                                            }}
                                            existingFile={content.cover}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="coverOrientation"
                                        label="Cover Orientation"
                                    >
                                        <Radio.Group
                                            options={[
                                                {
                                                    label: "Landscape",
                                                    value: "LANDSCAPE",
                                                },
                                                {
                                                    label: "Portrait",
                                                    value: "PORTRAIT",
                                                },
                                            ]}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </>
                            ) : (
                                ""
                            )}

                            <Form.Item
                                name="title"
                                label="Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input title!",
                                    },
                                ]}
                            >
                                <Input placeholder="Title" />
                            </Form.Item>

                            <Form.Item name="subtitle" label="Subtitle">
                                <Input placeholder="Subtitle" />
                            </Form.Item>

                            <Form.Item name="description" label="Description">
                                <Input.TextArea
                                    placeholder="Description"
                                    rows={6}
                                />
                            </Form.Item>

                            <div className="flex gap-6">
                                <Form.Item name="featured" label="Featured">
                                    <Switch
                                        defaultChecked={content.featured}
                                        onChange={(ev) => {
                                            setIsFeatured(ev);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="featuredUntil"
                                    label="Featured until (optional)"
                                >
                                    <DatePicker allowClear={true} />
                                </Form.Item>
                                <Form.Item
                                    name="isDownloadable"
                                    label="Downloadable"
                                >
                                    <Switch
                                        defaultChecked={content.isDownloadable}
                                    />
                                </Form.Item>
                            </div>

                            {isFeatured ? (
                                <>
                                    <Form.Item
                                        name="coverFeaturedContent"
                                        label="Featured Cover Image (10:3)"
                                    >
                                        <FileUpload
                                            triggerUpload={
                                                triggerFeaturedCoverUpload
                                            }
                                            onUploadComplete={(media: any) => {
                                                setTriggerFeaturedCoverUpload(
                                                    false
                                                );
                                                if (media) {
                                                    setFormData(
                                                        (currentState: any) => {
                                                            return {
                                                                ...currentState,
                                                                coverFeaturedContent:
                                                                    media?._id ||
                                                                    media?.id,
                                                            };
                                                        }
                                                    );
                                                }

                                                setTriggerUploadContent(true);
                                            }}
                                            existingFile={
                                                content.coverFeaturedContent
                                            }
                                        />
                                    </Form.Item>
                                </>
                            ) : (
                                ""
                            )}

                            <Form.Item
                                name="channels"
                                label="Channels"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input title!",
                                    },
                                ]}
                            >
                                <ChannelInput
                                    defaultValue={content?.channels.map(
                                        (x: any) => x.id
                                    )}
                                />
                            </Form.Item>

                            <Form.Item name="tags" label="Tags">
                                <Select
                                    placeholder="Add tags"
                                    allowClear
                                    mode="tags"
                                    notFoundContent={
                                        <div>
                                            Type the keyword and press enter to
                                            create a new tag.
                                        </div>
                                    }
                                ></Select>
                            </Form.Item>

                            <Form.Item
                                name="socialMediaUsage"
                                label="Social Media Usage"
                            >
                                <Select
                                    placeholder="Select social media usage"
                                    allowClear
                                    mode={"multiple"}
                                >
                                    {socialMedias.map((socialMedia) => {
                                        return (
                                            <Option
                                                key={socialMedia}
                                                value={socialMedia}
                                            >
                                                {socialMedia}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="mediaType"
                                label="Content Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select content type!",
                                    },
                                ]}
                                initialValue={content.media.type.toUpperCase()}
                            >
                                <Select
                                    placeholder="Select video type"
                                    // defaultValue={}
                                    onChange={(val: string) => {
                                        setContent((currentState: any) => {
                                            return {
                                                ...currentState,
                                                media: {
                                                    ...content.media,
                                                    type: val.toLowerCase(),
                                                },
                                            };
                                        });
                                    }}
                                >
                                    <Option value="VIDEO">Video</Option>
                                    <Option value="AUDIO">Audio</Option>
                                    <Option value="IMAGE">Image</Option>
                                </Select>
                            </Form.Item>

                            {content.media.type === "video" && (
                                <Form.Item
                                    name="subCategory"
                                    label="Video Type"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select video type!",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select video type"
                                        defaultValue={content.subCategory}
                                    >
                                        <Option value="stiry_story">
                                            Stiry+ Original Story
                                        </Option>
                                        <Option value="short">Short</Option>
                                        <Option value="video_podcast">
                                            Video Podcast
                                        </Option>
                                        <Option value="reels">Reels</Option>
                                    </Select>
                                </Form.Item>
                            )}

                            <Form.Item>
                                <div className="flex gap-4">
                                    <Popconfirm
                                        title="Are you sure? This action is not reversible."
                                        icon={
                                            <QuestionCircleOutlined
                                                style={{ color: "red" }}
                                            />
                                        }
                                        onConfirm={() => {
                                            deleteContent(content.id)
                                                .then((resp) => {
                                                    toast.success(
                                                        "Content deleted successfully."
                                                    );
                                                    history.push(
                                                        "/content/" +
                                                            content.channels[0]
                                                                ?.id
                                                    );
                                                })
                                                .catch((err) => {
                                                    console.log(
                                                        "🚀 ~ err",
                                                        err
                                                    );
                                                });
                                        }}
                                    >
                                        <Button
                                            className={"flex-1"}
                                            htmlType="button"
                                        >
                                            Delete
                                        </Button>
                                    </Popconfirm>
                                    <Button
                                        className={"flex-1"}
                                        htmlType="button"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={"flex-1"}
                                        type="primary"
                                        htmlType="submit"
                                        loading={isSaving}
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>

                    <div className={"flex-1"}>
                        <div className={"pb-3"}>Preview</div>
                        <MediaPreview
                            media={content.media}
                            poster={content.cover.url}
                            setDuration={setDuration}
                        />

                        <div className="space-y-2 mt-6">
                            <div>Media</div>
                            <div>
                                <FileUpload
                                    triggerUpload={triggerUploadContent}
                                    onUploadComplete={(media: any) => {
                                        setTriggerUploadContent(false);
                                        if (media) {
                                            setFormData((currentState: any) => {
                                                return {
                                                    ...currentState,
                                                    media:
                                                        media?._id || media?.id,
                                                };
                                            });
                                        }

                                        setSaveContent((currentState) => {
                                            return currentState + 1;
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditContent;
