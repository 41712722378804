import gql from "graphql-tag";
import { userFieldsFragment } from "../user/user.gql";

export const channelFieldsFragment = gql`
    fragment channelFields on Channel {
        id
        name
        description
        admins {
            ...userFields
        }
        cover {
            id
            url
            type
            createdAt
            updatedAt
        }
        createdAt
        updatedAt
    }

    ${userFieldsFragment}
`;

export const channelsQuery = gql`
    query Channels($pagingInput: PagingInput, $filter: ChannelFilterInput) {
        channels(pagination: $pagingInput, filter: $filter) {
            data {
                id
                name
            }
            pagingMeta {
                count
                startIndex
                endIndex
                hasNextPage
            }
        }
    }
`;

export const channelQuery = gql`
    query Channel($id: String!) {
        channel(id: $id) {
            ...channelFields
        }
    }
    ${channelFieldsFragment}
`;

export const createChannelMutation = gql`
    mutation CreateChannel($createChannelInput: CreateChannelInput!) {
        createChannel(createChannelInput: $createChannelInput) {
            ...channelFields
        }
    }

    ${channelFieldsFragment}
`;

export const updateChannelMutation = gql`
    mutation UpdateChannel($updateChannelInput: UpdateChannelInput!) {
        updateChannel(updateChannelInput: $updateChannelInput) {
            ...channelFields
        }
    }

    ${channelFieldsFragment}
`;

export const removeChannelMutation = gql`
    mutation RemoveChannel($id: String!) {
        removeChannel(id: $id) {
            ...channelFields
        }
    }

    ${channelFieldsFragment}
`;
