import React, { useContext, useState } from "react";
import { Layout, Menu } from "antd";
import logoImage from "./../../../assets/images/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AuthActions, AuthContext } from "../context/auth";

const { Header, Content, Footer } = Layout;
const DashboardLayout = ({ children }: any) => {
    const { authState, dispatch } = useContext(AuthContext);
    const location = useLocation();
    const customSubmenuStyle = {
        width: '80px',
    };

    const customMenuItemStyle = {
        padding: '2px 2px',
        color: '#333',
    };


    const currentRoute = location.pathname;
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Header className="bg-primary-white shadow-md z-10 ">
                <div className="container flex items-center justify-between m-auto">
                    <div className={"flex gap-6 items-center text-base"}>
                        <Link to={"/"} className="logo">
                            <img
                                src={logoImage}
                                alt="logo"
                                className={"w-16"}
                            />
                        </Link>

                        <Link to={"/"}>Dashboard</Link>
                        <Link to={"/dashboard-v2"}>Dashboard-v2</Link>
                        {authState.user.role.name ===
                            "Content Manager" ? null : (
                            <>
                                <Link to={"/content"}>Content</Link>

                                <Menu mode="horizontal" selectedKeys={[currentRoute]}>
                                    <Menu.SubMenu
                                        key="users"
                                        title="Users"
                                        style={customSubmenuStyle}  
                                    >
                                        <Menu.Item
                                            key="user-list"
                                            style={customMenuItemStyle}  
                                        >
                                            <Link to={'/users'}>Users List</Link>
                                        </Menu.Item>
                                        <Menu.Item
                                            key="codes"
                                            style={customMenuItemStyle}
                                        >
                                            <Link to={'/settings'}>View Stats</Link>
                                        </Menu.Item>
                                        <Menu.Item
                                            key="code-settings"
                                            style={customMenuItemStyle} // Apply custom menu item styles
                                        >
                                            <Link to={'/codes'}>Settings</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu>
                                </Menu>
                            </>
                        )}
                    </div>

                    <div className={"flex gap-4"}>
                        <Link to={"#"}>
                            {authState.user.firstName}&nbsp;
                            {authState.user.lastName}
                        </Link>
                        <Link
                            to={"#"}
                            onClick={() => {
                                dispatch({
                                    action: AuthActions.LOGOUT,
                                    data: {},
                                });
                            }}
                        >
                            Logout
                        </Link>
                    </div>
                </div>
            </Header>

            <Layout className="site-layout">
                <Content className={"container m-auto py-6"}>
                    {children}
                </Content>
                <Footer
                    className={
                        "bg-white text-center border-t border-primary-gray p-3"
                    }
                    style={{ height: 48 }}
                >
                    Stiry © {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default DashboardLayout;
