import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const VideoJS = (props: any) => {
    const videoRef = useRef(null);
    const playerRef = useRef<any>(null);
    const { options, isAudio, onReady } = props;

    useEffect(() => {
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = (playerRef.current = videojs(
                videoElement,
                options,
                () => {
                    console.log("player is ready");
                    onReady && onReady(player);
                }
            ));
        } else {
            // you can update player here [update player through props]
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, []);

    return (
        <div data-vjs-player data-is-audio={isAudio}>
            <video
                ref={videoRef}
                className="video-js vjs-big-play-centered"
            ></video>
        </div>
    );
};

export default VideoJS;
