import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Select, Popconfirm } from "antd";
import { getRoles } from "../../shared/services/role/role.service";
import { useHistory, useRouteMatch } from "react-router";
import {
    addUser,
    deleteUser,
    getUserById,
    updateUser,
    UserListContext,
} from "../../shared/services/user/user.service";
import { toast } from "react-toastify";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { AuthContext } from "../../shared/context/auth";
import ChannelInput from "../../shared/components/ChannelInput";
const { Option } = Select;

const UserForm = () => {
    const [roles, setRoles] = useState([]);

    const [checkedList, setCheckedList] = useState<any>([]);

    const [defaultCheckedList, setDefaultCheckedList] = useState<any>([]);

    const { params } = useRouteMatch<{ id: string }>();

    const history = useHistory();

    const [user, setUser] = useState<any>();

    const [userForm] = Form.useForm();

    const { dispatch } = useContext(UserListContext);

    const { authState } = useContext(AuthContext);

    useEffect(() => {
        getRoles()
            .then((resp) => {
                setRoles(resp.response);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, []);

    useEffect(() => {
        if (params.id) {
            getUserById(params.id)
                .then((data) => {
                    setUser(data.response);

                    setCheckedList(() => {
                        return [
                            ...(data?.response?.channels || []).map(
                                (x: any) => x.id
                            ),
                        ];
                    });

                    setDefaultCheckedList(() => {
                        return [
                            ...(data?.response?.channels || []).map(
                                (x: any) => x.id
                            ),
                        ];
                    });

                    userForm.setFieldsValue({
                        ...data.response,
                        role: data.response?.role?.id,
                    });
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
        } else {
            userForm.resetFields();
            setUser(null);
        }
    }, [params]);

    return (
        <>
            <div className="text-2xl mb-4">{user ? "Edit" : "Add"} User</div>
            <div className="w-2/4">
                <Form
                    form={userForm}
                    name="UserForm"
                    layout="vertical"
                    onFinish={(values) => {
                        values["channels"] = checkedList;
                        if (user) {
                            values["id"] = params.id;
                            if (!values.password) {
                                delete values["password"];
                            }

                            updateUser(values)
                                .then((resp) => {
                                    toast.success("User saved successfully.");
                                    dispatch({ action: "REFRESH_LIST" });
                                    userForm.resetFields();
                                })
                                .catch((err) => {
                                    console.log("🚀 ~ err", err);
                                });
                        } else {
                            addUser(values)
                                .then((resp) => {
                                    toast.success("User saved successfully.");
                                    dispatch({ action: "REFRESH_LIST" });
                                    userForm.resetFields();
                                })
                                .catch((err) => {
                                    console.log("🚀 ~ err", err);
                                });
                        }
                    }}
                >
                    <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input first name!",
                            },
                        ]}
                    >
                        <Input placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: "Please input last name!",
                            },
                        ]}
                    >
                        <Input placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                        name="role"
                        label="Role"
                        rules={[{ required: true }]}
                    >
                        <Select placeholder="Select a role" allowClear>
                            {roles.map((role: any) => {
                                return (
                                    <Option value={role.id} key={role.id}>
                                        {role.name === "Content Manager"
                                            ? "Business User"
                                            : role.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item name="channels" label="Channels">
                        <ChannelInput
                            onChange={(list: any) => {
                                setCheckedList(list);
                            }}
                            defaultValue={defaultCheckedList}
                        />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: "Please input email!",
                            },
                        ]}
                    >
                        <Input placeholder="Email" type="email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: user === null,
                                message: "Please input password!",
                            },
                        ]}
                    >
                        <Input placeholder="Password" type="password" />
                    </Form.Item>
                    <Form.Item>
                        <div className="flex gap-4">
                            {user && user.id !== authState.user.id ? (
                                <Popconfirm
                                    title="Are you sure? This action is not reversible."
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{ color: "red" }}
                                        />
                                    }
                                    onConfirm={() => {
                                        deleteUser(user.id)
                                            .then((resp) => {
                                                dispatch({
                                                    action: "REFRESH_LIST",
                                                });
                                                toast.success(
                                                    "User deleted successfully."
                                                );
                                                history.push("./");
                                            })
                                            .catch((err) => {
                                                console.log("🚀 ~ err", err);
                                            });
                                    }}
                                >
                                    <Button
                                        className={"flex-1"}
                                        htmlType="button"
                                    >
                                        Delete
                                    </Button>
                                </Popconfirm>
                            ) : (
                                <div className={"flex-1"}>&nbsp;</div>
                            )}
                            <Button className={"flex-1"} htmlType="reset">
                                Cancel
                            </Button>
                            <Button
                                className={"flex-1"}
                                type="primary"
                                htmlType="submit"
                            >
                                Save
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default UserForm;
