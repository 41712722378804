import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import axiosClient from "../../config/axiosClient";
import { uploadProgress } from "../services/content/content.service";
const { Dragger } = Upload;

const MultipleFileUpload = ({ triggerUpload, onUploadComplete }: any) => {
    const [fileList, setFileList] = useState<any>([]);

    useEffect(() => {
        if (triggerUpload) {
            if (fileList.length) {
                new Promise((resolve, reject) => {
                    const filesResponse: any[] = [];
                    let tempIndex = 0;
                    fileList.forEach((file: any) => {
                        const formData = new FormData();
                        const type = file.type.split("/")[0];
                        let duration = 0;

                        formData.append("file", file);
                        formData.append("type", type);

                        if (type === "video") {
                            const video = document.createElement("video");
                            video.preload = "metadata";

                            video.onloadedmetadata = function () {
                                window.URL.revokeObjectURL(video.src);
                                console.log("Duration:", video.duration);
                                duration = video.duration;
                            };

                            video.src = URL.createObjectURL(file);
                        }

                        axiosClient
                            .post("/file/upload", formData)
                            .then((resp: any) => {                            
                                const callback = () => {
                                    filesResponse.push({ ...resp, duration });

                                    if (tempIndex === fileList.length - 1) {
                                        setFileList([]);

                                        resolve(filesResponse);
                                    }

                                    tempIndex++;
                                };

                                if (resp.uploadStatus === "Completed") {
                                    callback();
                                } else if (resp.uploadStatus === "Uploading") {
                                    const vimeoUploadLink =
                                        resp.vimeoUploadLink;
                                    const uploadStatusCheckInterval =
                                        setInterval(() => {
                                            uploadProgress(
                                                vimeoUploadLink
                                            ).then((resp) => {
                                                const status =
                                                    resp.response.uploadStatus;
                                                if (status === "Completed") {
                                                    callback();
                                                    clearInterval(
                                                        uploadStatusCheckInterval
                                                    );
                                                }
                                            });
                                        }, 5000);
                                }
                            });
                    });
                })
                    .then((files: any) => {
                        setTimeout(() => {
                            onUploadComplete(files);
                        }, 1000);
                    })
                    .catch((err) => {
                        console.log("🚀 ~ err", err);
                    });
            }
        }
    }, [triggerUpload]);

    return (
        <Dragger
            name="file"
            showUploadList={true}
            multiple={true}
            className={
                "p-4 text-center border rounded flex justify-center cursor-pointer hover:bg-primary-white transition items-center border-dashed w-full"
            }
            beforeUpload={(file) => {
                setFileList((currentState: any) => {
                    return [...currentState, file];
                });
                return false;
            }}
            onRemove={(file) => {
                setFileList((currentState: any) => {
                    return [
                        ...currentState.filter((x: any) => x.uid !== file.uid),
                    ];
                });
            }}
        >
            <div>Click or Drag here to upload</div>
        </Dragger>
    );
};

export default MultipleFileUpload;
