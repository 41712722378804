import { gql } from "@apollo/client";

export const getReportsQuery = gql`
    query Reports($filter: ContentFilterInput) {
        reports(filter: $filter) {
            contents {
                id
                title
            }
            averageViewDuration
            totalViewDuration
            avgViewsUserPerSession
            avgUserSessionDuration
            totalContentViews
            totalContentDownloads
            totalDownloadsViewByDate {
                _id
                downloads
            }

            totalContentViewByDate {
                _id
                views
                averagePerDay
            }
            wordsSearch {
                word
                count
            }
            alsoWatched {
                ...entityFields
                title
                subtitle
                featured
                featuredUntil
                search
                channels {
                    ...channelFields
                }
                cover {
                    ...mediaFields
                }
                media {
                    ...mediaFields
                }
                socialMediaUsage
                socialCopy
                tags {
                    ...tagFields
                }
            }
        }
    }

    fragment entityFields on Entity {
        id
        createdAt
        updatedAt
    }

    fragment mediaFields on Media {
        url
        type
    }

    fragment channelFields on Channel {
        id
        name
        description
        cover {
            ...mediaFields
        }
    }

    fragment tagFields on Tag {
        id
        name
    }
`;

export const getReportsByContentQuery = gql`
    query reportByContent($id: String!, $filter: ContentFilterInput) {
        reportByContent(id: $id, filter: $filter) {
            contents {
                id
                title
            }
            averageViewDuration
            totalViewDuration
            avgViewsUserPerSession
            avgUserSessionDuration
            totalContentViews
            totalContentDownloads
            totalDownloadsViewByDate {
                _id
                downloads
            }

            totalContentViewByDate {
                _id
                views
                averagePerDay
            }
            wordsSearch {
                _id
                content
                count
            }
            alsoWatched {
                ...entityFields
                title
                subtitle
                featured
                featuredUntil
                search
                channels {
                    ...channelFields
                }
                cover {
                    ...mediaFields
                }
                media {
                    ...mediaFields
                }
                socialMediaUsage
                socialCopy
                tags {
                    ...tagFields
                }
            }
        }
    }

    fragment entityFields on Entity {
        id
        createdAt
        updatedAt
    }

    fragment mediaFields on Media {
        url
        type
    }

    fragment channelFields on Channel {
        id
        name
        description
        cover {
            ...mediaFields
        }
    }

    fragment tagFields on Tag {
        id
        name
    }
`;
