import React, { useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth";
import { Select, DatePicker } from "antd";
import { deleteCode, getCodes } from "../../shared/services/code/code.service";
import { toast } from "react-toastify";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Settings = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { authState } = useContext(AuthContext);

  const [codes, setCodes] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [chartData, setChartData] = useState<any[]>([]);

  const [refreshChannelList, setRefreshUserList] = useState(0);
  const [selectedCodeInfo, setSelectedCodeInfo] = useState<any>(null);
  useEffect(() => {
    getCodes()
      .then((resp) => {
        setCodes((currentState: any) => {
          return resp.response.data;
        });
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  }, [refreshChannelList]);

  useEffect(() => {
    if (authState.user.role.name === "Content Manager") {
      history.push("/");
    }
  }, []);

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dates;
    const thirtyDaysAgo = moment().subtract(30, "days");

    if (!startDate || !endDate || startDate.isBefore(thirtyDaysAgo)) {
      console.log("Date range is more than 30 days or not selected.");
      return;
    }

    const daysInRange = endDate.diff(startDate, "days") + 1;

    if (daysInRange > 30) {
      endDate.subtract(daysInRange - 30, "days");
    }

    const data = Array.from({ length: daysInRange }, (_, index) => ({
      code: startDate.clone().add(index, "days").format("MMM D"),
      count: Math.floor(Math.random() * 10), // Replace with your data
    }));

    setChartData(data);
    setDateRange([startDate, endDate]);
  };
  const handleCodeSelect = (value: any) => {
    const selectedCode = codes.find((code: any) => code.id === value);
    setSelectedCodeInfo(selectedCode);
  };

  return (
    <div>
      <div className={"space-y-6"}>
        <div className={"flex justify-center"}>
          <div className={"rounded p-6 w-1/4"}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              Select code
              <Select
                placeholder="Select a code"
                style={{ width: "50%" }}
                onChange={handleCodeSelect}
              >
                {codes.map((code: any) => (
                  <Option key={code.id} value={code.id}>
                    {code.code}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        {/* Display code information section */}
        {selectedCodeInfo && (
          <div className={"row flex justify-center"} style={{textAlign: "center"}}>
            <div className="rounded p-3 w-1/4">
              <h3 style={{ textDecoration: "underline" }}>General Information</h3>
              <p>Code creation date: {moment(selectedCodeInfo.createdAt).format("MM-DD-YYYY")}</p>
              <p>
                Created By: {selectedCodeInfo?.user?.firstName} {selectedCodeInfo?.user?.lastName}
              </p>
            </div>
          </div>
        )}
        <div className={"row flex justify-center"}>
          <div className={"rounded p-6 w-1/4"}>
            <RangePicker
              placeholder={["Start Date", "End Date"]}
              onChange={handleDateChange}
              value={dateRange}
              allowClear={false}
            />
          </div>
        </div>
        {/* Conditional rendering based on date range */}
        {dateRange[0] && dateRange[1] ? ( // Check if a date range is selected
          <div className={"row flex justify-center"}>
            <div className={"rounded p-6 w-3/4"}>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={chartData}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="code" />
                  <YAxis dataKey="count" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="count" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : ( // If no date range is selected, show a message
          <div className={"row flex justify-center"}>
            <p>Please select the date range</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
