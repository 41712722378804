import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, Radio, Select, Switch } from "antd";
import {
    FileImageOutlined,
    SoundOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";
import MediaPreview from "./MediaPreview";
import FileUpload from "../../shared/components/FileUpload";
import ChannelInput from "../../shared/components/ChannelInput";
import {
    createContentRequest,
    getSocialMedias,
} from "../../shared/services/content/content.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
const { Option } = Select;

const StepTwo = () => {
    const history = useHistory();

    const [activeTab, setActiveTab] = useState("video");

    const [tabContents, setTabContents] = useState<any>();

    const [activeMedia, setActiveMedia] = useState<any>();

    const [previewIcon, setPreviewIcon] = useState<any>();

    const [triggerUpload, setTriggerUpload] = useState(false);

    const [newContentFormData, setNewContentFormData] = useState<any>(
        JSON.parse(localStorage.getItem("newContentFormData") || "{}")
    );

    const [newContentFilesData, setNewContentFilesData] = useState<any>(
        JSON.parse(localStorage.getItem("newContentFilesData") || "{}")
    );

    const [saveContent, setSaveContent] = useState(0);

    const [updateCurrentTab, setUpdateCurrentTab] = useState(0);

    const [localCoverImage, setLocalCoverImage] = useState(null);

    const [localFeaturedCoverImage, setLocalFeaturedCoverImage] =
        useState(null);

    const [duration, setDuration] = useState(0);

    const [isFeatured, setIsFeatured] = useState(false);

    const [triggerFeaturedCoverUpload, setTriggerFeaturedCoverUpload] =
        useState(false);

    const [contentForm] = Form.useForm();

    const socialMedias = getSocialMedias();

    const [contentType, setContentType] = useState(activeTab);

    useEffect(() => {
        setTabContents(() => {
            return [
                ...newContentFilesData.filter((x: any) => x.type === activeTab),
            ];
        });

        setPreviewIcon(() => {
            if (activeTab === "audio") {
                return <SoundOutlined className={"text-8xl"} />;
            } else if (activeTab === "video") {
                return <VideoCameraOutlined className={"text-8xl"} />;
            } else {
                return <FileImageOutlined className={"text-8xl"} />;
            }
        });

        contentForm.setFieldsValue({
            tags: newContentFormData?.tags,
        });

        setContentType(activeTab);
    }, [activeTab, updateCurrentTab]);

    useEffect(() => {
        if (saveContent > 0) {
            const length = Number(activeMedia.duration || duration);
            console.log("🚀 ~ Content duration:", length);
            createContentRequest({
                ...newContentFormData,
                length,
                subCategory: newContentFormData.subCategory?.toUpperCase(),
            })
                .then((resp) => {
                    const savedMediaId = resp.response.media.id;

                    setActiveMedia((currentMedia: any) => {
                        return null;
                    });

                    setLocalCoverImage(null);

                    setNewContentFilesData((currentFilesData: any[]) => {
                        const tempFiles = currentFilesData.filter(
                            (x: any) => x._id !== savedMediaId
                        );

                        if (!tempFiles.length) {
                            history.push(
                                "/content/" + newContentFormData.channels[0]
                            );
                        }

                        return [...tempFiles];
                    });

                    setUpdateCurrentTab((currentState: number) => {
                        return currentState + 1;
                    });

                    toast.success("Content stored successfully.");
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
        }
    }, [saveContent]);

    return (
        <div className="flex gap-6">
            <div className={"w-2/4 space-y-4"}>
                <div className={"flex gap-4"}>
                    <div
                        className={`cursor-pointer ${
                            activeTab === "video" && "font-bold border-b"
                        }`}
                        onClick={() => setActiveTab("video")}
                    >
                        Videos
                    </div>
                    <div
                        className={`cursor-pointer ${
                            activeTab === "audio" && "font-bold border-b"
                        }`}
                        onClick={() => setActiveTab("audio")}
                    >
                        Audio
                    </div>
                    <div
                        className={`cursor-pointer ${
                            activeTab === "image" && "font-bold border-b"
                        }`}
                        onClick={() => setActiveTab("image")}
                    >
                        Images
                    </div>
                </div>
                {tabContents && (
                    <div className={"grid grid-cols-3 gap-4"}>
                        {tabContents.map((media: any) => {
                            return (
                                <div
                                    key={media._id}
                                    className={`aspect-w-1 aspect-h-1 bg-secondary-gray  w-full rounded  cursor-pointer`}
                                    onClick={() => {
                                        setActiveMedia(() => {
                                            return activeMedia === media
                                                ? null
                                                : media;
                                        });
                                    }}
                                >
                                    <div
                                        className={`w-full h-full grid place-items-center border rounded ${
                                            activeMedia?._id === media._id &&
                                            "border-primary-red"
                                        }`}
                                    >
                                        {previewIcon}
                                    </div>
                                </div>
                            );
                        })}

                        {!tabContents.length && (
                            <div>No {activeTab}s available.</div>
                        )}
                    </div>
                )}
            </div>
            <div className={"w-2/4 space-y-4"}>
                {activeMedia && (
                    <>
                        <MediaPreview
                            media={activeMedia}
                            setDuration={setDuration}
                        />
                        <Form
                            name="ContentForm"
                            layout="vertical"
                            onFinish={(values) => {
                                if (activeTab === "image") {
                                    setNewContentFormData(
                                        (currentState: any) => {
                                            return {
                                                ...currentState,
                                                ...values,
                                                media: activeMedia._id,
                                                cover: activeMedia._id,
                                            };
                                        }
                                    );
                                    setSaveContent((currentState) => {
                                        return currentState + 1;
                                    });
                                } else {
                                    setNewContentFormData(
                                        (currentState: any) => {
                                            return {
                                                ...currentState,
                                                ...values,
                                                media: activeMedia._id,
                                            };
                                        }
                                    );
                                    setTriggerUpload(true);
                                }
                            }}
                            form={contentForm}
                        >
                            {activeTab !== "image" ? (
                                <>
                                    <Form.Item name="cover" label="Cover Image">
                                        <FileUpload
                                            triggerUpload={triggerUpload}
                                            onUploadComplete={(media: any) => {
                                                setTriggerUpload(false);
                                                if (media) {
                                                    setNewContentFormData(
                                                        (currentState: any) => {
                                                            return {
                                                                ...currentState,
                                                                cover: media?._id,
                                                            };
                                                        }
                                                    );

                                                    setLocalCoverImage(media);
                                                }
                                                if (isFeatured) {
                                                    setTriggerFeaturedCoverUpload(
                                                        true
                                                    );
                                                } else {
                                                    setSaveContent(
                                                        (currentState) => {
                                                            return (
                                                                currentState + 1
                                                            );
                                                        }
                                                    );
                                                }
                                            }}
                                            existingFile={localCoverImage}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="coverOrientation"
                                        label="Cover Orientation"
                                    >
                                        <Radio.Group
                                            options={[
                                                {
                                                    label: "Landscape",
                                                    value: "LANDSCAPE",
                                                },
                                                {
                                                    label: "Portrait",
                                                    value: "PORTRAIT",
                                                },
                                            ]}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </>
                            ) : (
                                ""
                            )}

                            <Form.Item
                                name="title"
                                label="Title"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input title!",
                                    },
                                ]}
                            >
                                <Input placeholder="Title" />
                            </Form.Item>

                            <Form.Item name="subtitle" label="Subtitle">
                                <Input placeholder="Subtitle" />
                            </Form.Item>

                            <Form.Item name="description" label="Description">
                                <Input.TextArea
                                    placeholder="Description"
                                    rows={6}
                                />
                            </Form.Item>

                            <div className="flex gap-6">
                                <Form.Item name="featured" label="Featured">
                                    <Switch
                                        onChange={(ev) => {
                                            setIsFeatured(ev);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="featuredUntil"
                                    label="Featured until (optional)"
                                >
                                    <DatePicker allowClear={true} />
                                </Form.Item>
                                <Form.Item
                                    name="isDownloadable"
                                    label="Downloadable"
                                >
                                    <Switch />
                                </Form.Item>
                            </div>

                            {isFeatured ? (
                                <>
                                    <Form.Item
                                        name="coverFeaturedContent"
                                        label="Featured Cover Image (10:3)"
                                    >
                                        <FileUpload
                                            triggerUpload={
                                                triggerFeaturedCoverUpload
                                            }
                                            onUploadComplete={(media: any) => {
                                                setTriggerFeaturedCoverUpload(
                                                    false
                                                );
                                                if (media) {
                                                    setNewContentFormData(
                                                        (currentState: any) => {
                                                            return {
                                                                ...currentState,
                                                                coverFeaturedContent:
                                                                    media?._id ||
                                                                    media?.id,
                                                            };
                                                        }
                                                    );

                                                    setLocalFeaturedCoverImage(
                                                        media
                                                    );
                                                }
                                                setSaveContent(
                                                    (currentState) => {
                                                        return currentState + 1;
                                                    }
                                                );
                                            }}
                                            existingFile={
                                                localFeaturedCoverImage
                                            }
                                        />
                                    </Form.Item>
                                </>
                            ) : (
                                ""
                            )}

                            <Form.Item
                                name="channels"
                                label="Channels"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input title!",
                                    },
                                ]}
                            >
                                <ChannelInput
                                    defaultValue={newContentFormData?.channels}
                                />
                            </Form.Item>

                            <Form.Item name="tags" label="Tags">
                                <Select
                                    placeholder="Add tags"
                                    allowClear
                                    mode="tags"
                                    notFoundContent={
                                        <div>
                                            Type the keyword and press enter to
                                            create a new tag.
                                        </div>
                                    }
                                ></Select>
                            </Form.Item>

                            <Form.Item
                                name="socialMediaUsage"
                                label="Social Media Usage"
                            >
                                <Select
                                    placeholder="Select social media usage"
                                    allowClear
                                    mode={"multiple"}
                                >
                                    {socialMedias.map((socialMedia) => {
                                        return (
                                            <Option
                                                key={socialMedia}
                                                value={socialMedia}
                                            >
                                                {socialMedia}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="mediaType"
                                label="Content Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select content type!",
                                    },
                                ]}
                                initialValue={activeTab.toUpperCase()}
                            >
                                <Select
                                    placeholder="Select video type"
                                    onChange={(val: string) => {
                                        setContentType(val);
                                    }}
                                >
                                    <Option value="VIDEO">Video</Option>
                                    <Option value="AUDIO">Audio</Option>
                                    <Option value="IMAGE">Image</Option>
                                </Select>
                            </Form.Item>

                            {contentType.toLowerCase() === "video" && (
                                <Form.Item
                                    name="subCategory"
                                    label="Video Type"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Please select video type!",
                                        },
                                    ]}
                                >
                                    <Select placeholder="Select video type">
                                        <Option value="stiry_story">
                                            Stiry+ Original Story
                                        </Option>
                                        <Option value="short">Short</Option>
                                        <Option value="video_podcast">
                                            Video Podcast
                                        </Option>
                                        <Option value="reels">Reels</Option>
                                    </Select>
                                </Form.Item>
                            )}

                            <Form.Item>
                                <div className="flex gap-4">
                                    <div className="flex-1">&nbsp;</div>
                                    <Button
                                        className={"flex-1"}
                                        htmlType="button"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={"flex-1"}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
};

export default StepTwo;
