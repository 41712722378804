import React, { useContext, useEffect, useReducer, useState } from "react";
import {
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { AuthContext } from "../../shared/context/auth";
import styles from "./index.module.less";
import { Table, Button, Modal, DatePicker } from "antd";
import { CodeListContext, deleteCode, getCodes, updateCode } from "../../shared/services/code/code.service";
import { toast } from "react-toastify";
import moment from "moment";

const Codes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { authState } = useContext(AuthContext);

  const [codes, setCodes] = useState<any>([]);

  const [refreshChannelList, setRefreshUserList] = useState(0);

  const [state, dispatch] = useReducer((currentState: any, payload: any) => {
    if (payload.action === "REFRESH_LIST") {
      setRefreshUserList((currentState: number) => {
        return currentState + 1;
      });
    }
    return {};
  }, {});

  useEffect(() => {
    getCodes()
      .then((resp) => {
        setCodes((currentState: any) => {
          return resp.response.data;
        });
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  }, [refreshChannelList]);

  useEffect(() => {
    if (authState.user.role.name === "Content Manager") {
      history.push("/");
    }
  }, []);

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [deletingItem, setDeletingItem] = useState<any>(null);

  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [editingCode, setEditingCode] = useState<any>(null);
  const [editedCodeName, setEditedCodeName] = useState("");
  const [editedExpirationDate, setEditedExpirationDate] = useState<Date | null>(null);

  const showDeleteConfirmation = (item: any) => {
    setDeletingItem(item);
    setDeleteConfirmationVisible(true);
  };

  const showUpdateModal = (code: any) => {
    setEditingCode(code);
    setEditedCodeName(code.code);
    setEditedExpirationDate(new Date(code.expirationDate));
    setUpdateModalVisible(true);
  };

  const confirmDelete = () => {
    if (deletingItem) {
      deleteCode(deletingItem.id)
        .then((resp) => {
          dispatch({
            action: "REFRESH_LIST",
          });
          toast.success(
            "User deleted successfully."
          );
          history.push("/codes");
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    }
    setDeleteConfirmationVisible(false);
  };

  const cancelDelete = () => {
    setDeleteConfirmationVisible(false);
  };

  const handleUpdate = () => {
    if (editingCode) {
      // Implement your code update logic here
      // For example, you can make an API request to update the code
      // After updating, close the modal
      setUpdateModalVisible(false);

      // Update the table data to reflect the changes
      const updatedCodes = codes.map((code: any) => {
        if (editingCode && code.id === editingCode.id) {
          return {
            ...code,
            code: editedCodeName,
            expirationDate: editedExpirationDate,
          };
        }
        return code;
      });
      setCodes(updatedCodes);
      const value = {
        id:  editingCode.id,
        code: editedCodeName,
        expirationDate: editedExpirationDate
      };
      updateCode(value).then((resp) => {
        toast.success("Code updated successfully.");
        // dispatch({ action: "REFRESH_LIST" });
        history.push("/codes");
    }).catch((err: any) => {
        console.log("🚀 ~ err", err);
    })}
  };

  const cancelUpdate = () => {
    setUpdateModalVisible(false);
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Expiration Date",
      dataIndex: "expirationDate",
      key: "expirationDate",
      sorter: (a: any, b: any) => {
        const dateA: any = new Date(a.expirationDate);
        const dateB: any = new Date(b.expirationDate);
        return dateA - dateB;
      },
      render: (text: any, record: any) => {
        const expirationDate = new Date(record.expirationDate);
        return expirationDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <div>
          <Button type="primary" onClick={() => showDeleteConfirmation(record)}>
            Delete
          </Button>
          <Button className={styles.updateButton} onClick={() => showUpdateModal(record)}>
            Update
          </Button>
        </div>
      ),
    },
  ];

  const disabledDate = (current: any) => {
    // Disable dates before today
    return current && current < moment().startOf('day');
  };

  return (
    <CodeListContext.Provider value={{ dispatch }}>
      <div className={"space-y-6"}>
        <div className={"flex justify-center"}>
          <div className="w-3/5">
            <Table dataSource={codes} columns={columns} rowKey={(record) => record.id} />
          </div>
        </div>
        <div className={"flex justify-center"}>
          <Link to="/create-code">
            <div className={"rounded p-6"}>
              <div className={styles.listItem + " border-solid text-center"}>Add new code</div>
            </div>
          </Link>
        </div>
      </div>
      <Modal
        title="Confirmation"
        visible={deleteConfirmationVisible}
        onOk={confirmDelete}
        onCancel={cancelDelete}
      >
        <p>Are you sure you want to delete this code?</p>
      </Modal>
      <Modal
        title="Update Code"
        visible={updateModalVisible}
        onOk={handleUpdate}
        onCancel={cancelUpdate}
      >
        <div>
          <label>Code Name:</label>
          <input
            type="text"
            value={editedCodeName}
            onChange={(e) => setEditedCodeName(e.target.value)}
          />
        </div>
        <div>
          <label>Expiration Date:</label>
          <DatePicker
            value={editedExpirationDate ? moment(editedExpirationDate) : null}
            onChange={(date) => setEditedExpirationDate(date ? date.toDate() : null)}
            disabledDate={disabledDate} />
        </div>
      </Modal>
    </CodeListContext.Provider>
  );
};

export default Codes;
