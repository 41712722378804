import React, { useEffect, useState } from "react";
import { VideoJsPlayerOptions } from "video.js";
import VideoJS from "../../shared/components/VideoJS";
import Player from "@vimeo/player";

const MediaPreview = ({ media, poster, setDuration }: any) => {
    const [localMedia, setLocalMedia] = useState(media);

    useEffect(() => {
        setLocalMedia(media);
    }, [media]);

    const playerRef = React.useRef(null);

    const videoJsOptions: VideoJsPlayerOptions = {
        // lookup the options in the docs for more options
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [
            {
                src: media.url,
            },
        ],
        poster: poster,
    };

    const handlePlayerReady = (player: any) => {
        playerRef.current = player;
        // console.log("🚀 ~ player", player);

        setTimeout(() => {
            const duration = player.duration();
            if (!isNaN(duration)) {
                console.log("duration is ready: " + duration);
                setDuration(duration.toFixed());
            }
        }, 1000);

        // you can handle player events here
        player.on("waiting", () => {
            console.log("player is waiting");
        });

        player.on("dispose", () => {
            console.log("player will dispose");
        });
    };

    useEffect(() => {
        if (media.url.includes("vimeo")) {
            const vimeoPlayer = new Player("vimeoPlayer", {
                autoplay: true,
            });

            vimeoPlayer.on("play", function () {
                console.log("played the player 2.0 video!");
            });
        }
    }, [media]);

    return (
        <div className={"space-y-2"}>
            <div className={"w-full  bg-secondary-gray rounded"}>
                {localMedia.type.toLowerCase() === "image" && (
                    <div
                        className={
                            "w-full h-full grid place-items-center overflow-hidden"
                        }
                    >
                        <img
                            src={localMedia.url}
                            alt="Preview"
                            className={"max-w-full"}
                        />
                    </div>
                )}
                {(localMedia.type.toLowerCase() === "video" ||
                    localMedia.type.toLowerCase() === "audio") && (
                    <div
                        className={
                            "w-full h-full grid place-items-center overflow-hidden"
                        }
                    >
                        {media.url.includes("vimeo") ? (
                            <div
                                data-vimeo-url={media.url}
                                id="vimeoPlayer"
                                className="aspect-w-16 aspect-h-9 w-full"
                                data-vimeo-autoplay={true}
                            ></div>
                        ) : (
                            <VideoJS
                                options={videoJsOptions}
                                onReady={handlePlayerReady}
                                isAudio={
                                    localMedia.type.toLowerCase() === "audio"
                                }
                            />
                        )}
                    </div>
                )}
            </div>
            <div>
                File size: {((localMedia?.size || 0) / 1024 / 1024).toFixed(2)}
                MB
            </div>
        </div>
    );
};

export default MediaPreview;
